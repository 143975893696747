#modalQrCode {
  @apply flex items-center justify-center px-6;
  height: 100vh;
}

#modalQrCode .MuiBackdrop-root {
  height: 100vh;
}

#modalQrCode .modalContent {
  @apply w-full py-6 px-8 rounded-3xl;
  background-color: var(--bg-color);
}

#modalQrCode .boxQrCode {
  @apply p-4 rounded-none w-min mx-auto mb-1;
  background-color: #fff;
}

#modalQrCode .boxQrCode svg {
  @apply w-32 h-32;
}

#modalQrCode .modalInfos {
  @apply border-y py-1 text-center;
  border-color: var(--second-color);
}

#modalQrCode .modalTitle {
  @apply font-bold text-sm uppercase;
  color: var(--second-color);
}

#modalQrCode .modalCodeCall {
  @apply text-sm;
  color: var(--second-color);
}

#modalQrCode .modalCode {
  @apply text-xl font-semibold uppercase;
  color: var(--first-color);
}

#modalQrCode .modalBtn {
  @apply h-14 mt-10 mx-auto w-full block rounded-lg shadow-none text-sm;
  color: #fff;
  background-color: var(--main-color);
}
