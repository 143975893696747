@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--bg-color: #fff;
	--bg-navigatior: #fff;
	--first-color: #2f2f37;
	--second-color: #656572;
	--third-color: #9f9fad;
	--shadow-color: rgba(0, 0, 0, 0.04);
	--bg-icon: #9f9fad;
}

.scanner {
	aspect-ratio: 1;
}

.scanner video {
	object-fit: cover;
}

.scanner svg {
	max-width: 100%;
	max-height: 100%;
}

/* Código abaixo comentado devido a bug. É o código responsável pelo modo escuro. */
/* @media (prefers-color-scheme: dark) {
	:root {
		--bg-color: #36363a;
		--bg-navigatior: #292929;
		--first-color: #fff;
		--second-color: #fff;
		--third-color: #fff;
		--shadow-color: rgba(255, 255, 255, 0.04);
		--bg-icon: #36363a;
	}
	select option {
		color: black;
	}
} */

html {
	background: var(--bg-color) !important;
}

body {
	color: var(--first-color) !important;
	background: var(--bg-color) !important;
	letter-spacing: normal !important;
	max-width: 767px;
	margin: 0 auto !important;
	position: relative;
}

[data-rsbs-overlay],
.MuiPopover-paper {
	background-color: var(--bg-color) !important;
	max-width: 767px;
	margin: 0 auto !important;
}

.customDialog {
	max-width: 735px;
	margin: 0 auto;
}

.customDialog .MuiDialog-paper {
	background-color: var(--bg-color) !important;
}

.customDialog .MuiDialogContent-root {
	padding: 0 !important;
}

[data-rsbs-header]:before {
	background-color: var(--first-color);
}

.MuiFormLabel-root,
.MuiOutlinedInput-root,
.MuiSelect-iconOpen,
.MuiSelect-iconOutlined,
.MuiMenuItem-root {
	color: var(--second-color) !important;
}

.MuiOutlinedInput-notchedOutline {
	border-color: var(--second-color) !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: red !important;
}

.loadingBox {
	@apply flex items-center justify-center;
	min-height: 85vh;
}

.loadingBox .loading {
	color: var(--main-color);
}

.loadingDefault {
	@apply p-4 py-11 text-xl font-semibold flex items-center justify-center;
	min-height: 65vh;
	text-align: center;
	flex-direction: column;
	color: var(--first-color);
}

.loadingDefault .loading {
	@apply mb-4 w-full;
	color: var(--main-color);
}

.Mui-error.MuiFormHelperText-root {
	@apply text-sm font-semibold;
	color: red !important;
}

.MuiFormLabel-root.Mui-error {
	color: red !important;
}

.MuiPopover-paper {
	max-height: 600px !important;
}

.blockSelect {
	@apply relative w-full;
}

.select {
	@apply w-full pr-6 cursor-pointer;
	padding-left: 14px;
	border-radius: 4px;
	appearance: none;
	height: 56px;
	background-color: transparent;
	border: 1px solid var(--second-color);
	color: var(--second-color);
}

.blockSelect .selectIcon {
	position: absolute;
	right: 10px;
	top: 52%;
	transform: translateY(-50%);
	color: var(--second-color);
}

.select:focus,
.select:focus-visible {
	outline: #fff;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-space {
	margin: 0 auto;
}

.simple-keyboard {
	max-width: 80vw;
	margin: 0 auto;
	color: var(--bg-color) !important;
	background-color: var(--bg-color) !important;
	font-size: 1.5rem !important;
}

.simple-keyboard .hg-button-numbers {
	max-width: 27vw;
	margin: 0 auto;
}

.simple-keyboard .hg-standardBtn {
	max-width: 26vw;
	font-size: 1.5rem;
	background-color: var(--first-color) !important;
}
.hg-theme-default .hg-button {
	height: 80px;
	font-size: 1.1rem;
}

.simple-keyboard .hg-row {
	justify-content: center;
}

.hg-button {
	background-color: var(--first-color) !important;
}

.hg-layout-defaultNumber .hg-standardBtn {
	max-width: 26vw;
	font-size: 1.2rem;
}

.hg-theme-default .hg-button {
	/* height: 3.5vh !important; */
	/* border-radius: .5rem  !important; */
}

@import './layout/header.css';
@import './layout/bottomNavigator.css';
@import './pages/home.css';
@import './pages/menu.css';
@import './pages/informations.css';
@import './pages/search.css';
@import './pages/product.css';
@import './pages/cart.css';
@import './pages/order.css';
@import './pages/myItems.css';
@import './pages/payment.css';
@import './pages/success.css';
@import './pages/autoatendimento.css';
@import './pages/links.css';
@import './pages/kds.css';
@import './pages/kds-senha.css';
@import './pages/eventos.css';
@import './pages/tickets.css';
@import './pages/busca.css';
@import './pages/queue.css';
@import './pages/reservation.css';
@import './components/modalLogin.css';
@import './components/headerBottomSheet.css';
@import './components/notFound.css';
@import './components/footerBottomSheet.css';
@import './components/modalPix.css';
@import './components/modalQrCode.css';
@import './components/productItem.css';
@import './components/modalLoading.css';
@import './components/rating.css';
@import './components/modalWaiter.css';
@import './components/modalThumb.css';
@import './components/modalCoupon.css';
@import './components/fidelity.css';
@import './components/appBar.css';
@import './pages/delivery/payment.css';
@import './pages/delivery/registerAddress.css';
@import './pages/delivery/searchCompany.css';
@import './pages/delivery/companiesList.css';
@import './pages/pos.css';
@import './pages/thermalPrinterModels.css';
@import './pages/controleFila.css';
