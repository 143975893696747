#modalPix {
	@apply flex items-center justify-center h-full w-full px-6;
}

#modalPix .pixContent {
	@apply border rounded-3xl py-4 px-3 w-full flex items-center justify-center relative z-50;
	border-color: var(--second-color);
	background-color: var(--bg-color);
	max-width: 500px;
}

#modalPix .pixTitle {
	@apply font-semibold mb-2 text-center;
	color: var(--first-color);
}

#modalPix .pixDesc {
	color: var(--second-color);
}

#modalPix .pixDesc span {
	@apply font-semibold;
}

#modalPix .blockKey {
	@apply my-4 mx-auto block;
	color: var(--second-color) !important;
	max-width: 275px;
}

#modalPix .blockKey * {
	color: var(--second-color) !important;
	-webkit-text-fill-color: var(--second-color) !important;
}

#modalPix .timeTitle {
	@apply text-center;
	color: var(--second-color);
}

#modalPix .time {
	@apply text-center mb-4 text-2xl font-semibold;
	color: var(--second-color);
}

#modalPix .btnPix {
	@apply w-full h-14 text-lg font-semibold mx-auto block;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
	max-width: 260px;
}

#modalPix .statusTitle {
	@apply text-center text-3xl font-semibold;
	color: var(--first-color);
}

#modalPix .statusVerified {
	@apply text-center text-xl font-semibold ml-8 mr-8;
	color: var(--first-color);
}

#modalPix .statusText {
	@apply text-center text-sm font-normal mt-2 ml-8 mr-8 mb-6;
	color: var(--first-color);
}

#modalPix .btnOther {
	@apply w-full h-14 text-lg font-semibold;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
}

#modalPix .iconHolder svg {
	@apply mx-auto mb-9 w-16 h-16 mt-6;
}

#modalPix .btnVerified {
	@apply w-52 h-14 font-semibold;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none !important;
	font-size: 1.125rem !important;
	color: #fff !important;
}

#modalPix .btnContainer {
	@apply align-middle w-full flex justify-center mb-8;
}
