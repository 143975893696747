#cart .content {
	@apply p-4;
}

#cart .form {
	@apply mb-6;
	position: relative;
}

#cart .form .MuiPopover-paper {
	margin-top: -45px !important;
	max-height: 300px !important;
}

#cart .item {
	@apply mb-4 flex items-center gap-2;
}

#cart .itemImg {
	@apply w-14 h-14 bg-cover bg-center rounded-md;
}

#cart .itemContent {
	@apply flex-1;
}

#cart .itemPrice {
	@apply text-xl font-semibold;
	color: var(--first-color);
}

#cart .itemPrice span {
	@apply text-sm;
}

#cart .itemTitle {
	color: var(--second-color);
}

#cart .itemOptions {
	@apply text-xs;
	color: var(--second-color);
}

#cart .itemBtns {
	@apply flex items-center gap-2;
}

#cart .itemBtn {
	@apply p-1 min-w-0 w-8 h-6;
	color: #656572;
	background-color: #ddd;
}

#cart .itemQtd {
	@apply w-6 text-center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 2.5vw;
	color: var(--second-color);
}

#cart .btnRemove {
	@apply ml-2 rounded-lg w-8 h-8;
	background-color: #ff5252;
	min-width: auto;
}

#cart .btnRemove svg {
	color: #fff;
}

#cart .relatedProducts {
	@apply pl-4 pt-4;
}

#cart .relatedProductsTitle {
	@apply mb-2 font-semibold;
	color: var(--first-color);
}

#cart .relatedItem {
	@apply w-24;
}

#cart .relatedItem:last-child {
	@apply mr-4;
}

#cart .relatedBtn {
	@apply block p-0 text-left w-full;
	letter-spacing: normal;
	text-transform: none;
	font-size: initial;
}

#cart .relatedImg {
	@apply h-36 w-24 rounded-lg bg-cover bg-center;
}

#cart .relatedName {
	@apply text-sm font-semibold text-center mt-2;
	color: var(--third-color);
}

#cart .swiper-pagination {
	@apply flex items-center justify-center mt-4 relative;
}

#cart .swiper-pagination-bullet {
	@apply w-2 h-2 opacity-100;
	background: #dedee3;
}

#cart .swiper-pagination-bullet-active {
	@apply w-3 h-3;
	background: var(--first-color);
}
