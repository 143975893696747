#reservationPage {
	@apply flex flex-col;
}

#reservationPage .banner {
	@apply bg-cover bg-center h-40 ;
}

#reservationPage .imgClient {
	@apply w-20 h-20 rounded-full bg-cover bg-center relative ml-6 -mt-10;
	box-shadow: 0px -1px 6px 8px var(--shadow-color);
}

#reservationPage .boxBackgroundFidelidade {
	position: absolute;
	height: 2vh;
	width: 100%;
	display: flex;
	background: white;
	border-radius: 8px 8px 0 0;
	align-items: center;
	justify-content: center;
}

#reservationPage .header {
	@apply px-4 pt-6;
}

#reservationPage .clientName {
	@apply text-xl font-semibold mb-2;
	color: var(--first-color);
}

#reservationPage .clientLocation {
	@apply text-sm mb-4;
	color: var(--second-color);
}

#reservationPage .content {
	@apply flex-1;
}

#reservationPage .tabs {
	@apply px-4;
}

#reservationPage .tabsItem {
	@apply mb-2;
}

#reservationPage .tabsHead {
	@apply h-9 flex items-center justify-between px-3 w-full;
	background-color: #ddd !important;
	box-shadow: none !important;
}

#reservationPage .tabsTitle {
	@apply text-sm;
	color: #656572;
	text-transform: none;
}

#reservationPage .tabsBody {
	@apply mt-2;
}

#reservationPage .tabsTxt {
	@apply text-sm;
	color: var(--second-color);
	white-space: break-spaces;
}

#reservationPage .space {
	@apply px-4 pt-2;
}

#reservationPage .spaceTitle {
	@apply mb-4 flex items-center gap-1;
	color: var(--second-color);
}

#reservationPage .spaceList {
	@apply flex gap-3 flex-wrap;
}

.lightbox-container {
	background-color: rgba(0, 0, 0, 0.93);
}

.lightbox-container svg {
	color: #fff !important;
}

#reservationPage .spaceItem {
	@apply w-12 h-12 bg-cover bg-center rounded-lg relative overflow-hidden;
}

#reservationPage .spaceItemMore {
	@apply absolute w-full h-full top-0 left-0 flex items-center justify-center text-sm;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.5);
}

#reservationPage .form {
	@apply border-b mt-6;
	border-color: var(--second-color);
}

#reservationPage .formItem {
	@apply flex items-center;
}

#reservationPage .formIcon {
	@apply w-12 border-t flex items-center justify-center relative h-14;
	border-color: var(--second-color);
}

#reservationPage .formIconWhite img {
	filter: brightness(0) invert(1);
}

#reservationPage .formInput {
	@apply w-full  bg-transparent;
}

#reservationPage .select {
	@apply border-x-0 rounded-none border-b-0;
}

#reservationPage .selectCustom {
	width: 100%;
	background-color: transparent;
	border: 1px solid #fff;
	border-radius: 4px;
	height: 56px;
	padding: 10px;
}

.light #reservationPage .selectCustom {
	border: 1px solid #656572 !important;
}

#reservationPage .inputDate {
	@apply w-full border-x-0 rounded-none border-b-0;
}

#reservationPage .inputDate .Mui-disabled {
	@apply opacity-70;
	color: var(--second-color) !important;
	-webkit-text-fill-color: var(--second-color) !important;
}

#reservationPage .inputDate .MuiFormLabel-root.Mui-error {
	color: var(--second-color) !important;
}

#reservationPage .inputDate fieldset {
	border-color: var(--second-color) !important;
}

#reservationPage .inputDate fieldset,
#reservationPage .formInput fieldset {
	@apply border-x-0 rounded-none border-b-0;
}

#reservationPage .footer {
	@apply p-4;
}

#reservationPage .btnDefault {
	@apply w-full h-14 text-lg font-semibold;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
}

#reservationPage .btnDefault.Mui-disabled {
	@apply opacity-50;
}

#reservationPage .backBtn {
	@apply text-base font-normal ml-2;
	box-shadow: none !important;
	text-transform: none;
	color: var(--second-color) !important;
}

#reservationPage .success {
	@apply text-center;
}

#reservationPage .successIcon svg {
	@apply mx-auto mb-9 w-16 h-16 mt-6;
}

#reservationPage .successMsg {
	@apply text-xl font-semibold;
	color: var(--first-color);
}

#reservationPage .successOption {
	@apply mt-9;
}

#reservationPage .successOptionIcon {
	@apply mb-1;
	color: var(--second-color);
}

#reservationPage .successOptionIcon svg {
	@apply mx-auto w-8 h-auto;
}

#reservationPage .successOption a {
	@apply text-sm inline-block;
	color: var(--second-color);
}
