#searchCompany {
  @apply flex flex-col pb-6;
}

#searchCompany .container {
  @apply flex-1 px-4 pt-16;
}

#searchCompany .call {
  @apply text-base text-center mb-2 font-semibold;
}

#searchCompany .subCall {
  @apply text-sm leading-6 text-center mb-8;
}

#searchCompany .getLocation {
  @apply w-full h-14 text-sm font-bold text-white shadow-none gap-3;
  background-color: #5e5e5e;
}

#searchCompany .searchBlock {
  @apply mb-6 flex relative mt-6;
}

#searchCompany .btnSearch {
  @apply w-6 h-14 rounded-l-none absolute right-0;
  color: var(--second-color);
}

#searchCompany .footer .btnAll {
  @apply block mx-auto;
}

#searchCompany .footer .btnAllTxt {
  @apply text-sm uppercase;
  color: var(--first-color);
}
