#home {
	@apply p-4 mt-5;
}

#home .list {
	@apply flex items-center justify-center flex-col gap-7;
}

#home .card {
	@apply rounded-lg bg-cover bg-center flex items-center p-6 w-full h-36 relative overflow-hidden;
}

@media screen and (orientation: landscape) {
	#home .card {
		 height: 12rem;
	}
}

#home .cardWithName::before {
	content: '';
	@apply absolute w-full h-full bg-black opacity-50 left-0 top-0;
}

#home .cardContent {
	@apply flex items-center justify-end relative flex-1;
}

#home .cardContentWithName {
	@apply justify-between;
}

#home .cardTitle {
	@apply text-xl font-semibold;
	color: #fff;
}

#home .cardBtn {
	@apply flex items-center justify-center rounded-lg h-11 w-11 opacity-80;
	background: #86868a;
}

#home .logo {
	@apply bg-cover bg-center w-11 h-11 rounded-full mr-4;
}

#home .btnAll {
	@apply w-full h-14 mt-8 text-lg font-semibold mx-auto block;
	max-width: 220px;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
}

#home .storeStatus {
	@apply text-xs px-2 py-1 rounded-full mt-1 font-semibold;
	color: #fff;
	background-color: green;
}

#home .storeStatus.closed {
	background-color: red;
}
