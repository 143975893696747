#footerBottomSheet {
	@apply py-6 px-4;
}

#footerBottomSheet .footerContent {
	@apply flex gap-x-4 justify-between items-end;
}

#footerBottomSheet .footerTotTxt {
	@apply text-lg font-semibold;
	color: var(--first-color);
}

#footerBottomSheet .footerQtdTxt {
	@apply text-base;
	color: var(--second-color);
}

#footerBottomSheet .footerValue {
	@apply text-4xl font-semibold;
	color: var(--first-color);
}

#footerBottomSheet .footerValue span {
	@apply text-xl;
}

#footerBottomSheet .btnDefault {
	@apply w-full h-14 mt-8 text-lg font-semibold;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
}

#footerBottomSheet .btnDefault.Mui-disabled {
	@apply opacity-50;
}

#footerBottomSheet .btnOther {
	@apply w-full h-14 text-lg font-semibold;
	background-color: 'success' !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
}

#footerBottomSheet .row {
	@apply flex gap-x-4 justify-between items-end;
}

#footerBottomSheet .footerSubtotTxt {
	@apply text-sm;
	color: var(--second-color);
}
