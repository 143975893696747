/* #kds .container {
background:#212121 ;
height: 100vh;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
} */

/* display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'  */

#kds .largeGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}




@media screen and (max-width:1920px ){
    /* #kds .gridResponisvo{
        background-color: pink ;
    }
     */
    
}