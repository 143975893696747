#modalLogin {
  @apply flex items-center justify-center h-full w-full px-6;
}

#modalLogin .content {
  @apply w-full py-6 px-8 rounded-3xl;
  background-color: var(--bg-color);
}

#modalLogin .title {
  @apply text-center mb-3 font-semibold text-3xl;
  color: var(--first-color);
}

#modalLogin .field {
  @apply mb-3;
}

#modalLogin .field svg {
  color: var(--second-color);
}

#modalLogin .btn {
  @apply h-14 mt-3 mx-auto w-full block rounded-lg shadow-none text-sm;
  max-width: 210px;
  background-color: var(--main-color);
}

#modalLogin .rowDate {
  @apply flex gap-4;
}

#modalLogin .blockSelect {
  @apply mb-3;
}

#modalLogin .dateLabel {
  @apply text-sm mb-1;
  margin-top: -4px;
  color: var(--second-color);
}
