#kds .MuiOutlinedInput-root,
.MuiSelect-iconOpen,
.MuiSelect-iconOutlined,
.MuiMenuItem-root {
	color: black !important;
}

#kds .MuiFormLabel-root {
	color: black !important;
	font-weight: 700;
}

#kds .MuiOutlinedInput-notchedOutline {
	border-color: black !important;
}

#kds [data-rsbs-overlay],
.MuiPopover-paper {
	background-color: 'black' !important;
}

#kds .MuiInputBase-input {
	color: black !important;
	-webkit-text-fill-color: black !important;
}

#kds .cardsContainer {
	width: 100%;
	columns: 1;
	column-gap: 0rem;
	row-gap: 30rem;
	height: calc(100vh - 4.75rem);
	column-fill: auto;
}

#kds .kdsCard {
	@apply ml-1 mb-3;
	width: 97%;
}

#kds .cardTitle {
	@apply text-left font-bold text-xl text-white ml-2;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

#kds .cardCounter {
	@apply font-bold text-xl text-white;
}

#kds .itemsName {
	@apply text-base font-medium;
	width: 85%;
}

#kds .cardSubTitle {
	@apply text-center text-xs;
	max-height: 3.5rem;
	text-overflow: ellipsis;
}

#kds .cardTitleIcon {
	@apply text-4xl;
	text-align: right;
	color: white;
}

#kds .cardTitleTrashIcon {
	@apply text-2xl;
	text-align: right;
	color: white;
}

#kds .itemsQuantity {
	@apply font-bold;
	width: 5%;
}

#kds .newItemsQuantity {
	@apply font-bold;
	width: 5%;
}

#kds .itemsTimer {
	@apply font-bold text-center py-2 ms-1;
	background-color: rgb(206, 206, 206);
	border-radius: 0.3rem;
	width: 30%;
	font-size: 0.8rem;
}

#kds .itemsTimer:hover {
	background-color: rgb(186, 186, 186);
	cursor: pointer;
}

#kds .cardTitleContainer {
	max-height: 3.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

#kds .itemsObs {
	@apply font-bold;
	padding: 0.2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-top: 0.5rem;
	min-width: 50%;
	max-width: 100%;
	width: fit-content;
	border-radius: 0.4rem;
	background-color: red;
	color: white;
}

#kds .kdsItemsContainer {
	position: relative;
	background-color: rgb(243, 243, 243);
	border-radius: 0.5rem;
	padding: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	margin-bottom: 1rem;
}

#kds .kdsBoxItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

#kds .kdsDone {
	text-decoration: line-through;
}

#kds .hidden {
	visibility: hidden;
}

#kds .buttonLateral {
	position: fixed;
	bottom: 2vh;
	right: 2vw;
}

#kds .boxLateral {
	width: fit-content;
	position: fixed;
	bottom: 10vh;
	top: 10vh;
	right: 2vw;
	border-radius: 0.3rem;
	background-color: #d9d9d9;
	border: 1px solid #9d9d9d;
	color: black;
	height: auto;
}

#kds .boxItemLateral {
	display: flex;
	flex-direction: row;
	gap: 0.1rem;
	margin: 0.5rem;
	background-color: white;
	padding: 0.1rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 0.2rem;
	min-width: 230px;
}

#kds .boxHeader {
	text-align: center;
	margin: 1.3rem;
}

#kds .itemLateral {
	min-width: 3vw;
}

#kds .appBarItem {
	@apply text-xs;
	width: 80vw;
	overflow: scroll;
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#kds .appBarItem::-webkit-scrollbar {
	display: none !important;
}

#kds .boldItem {
	@apply font-bold;
}

@media screen(sm) {
	#kds .cardsContainer {
		width: auto;
		columns: 2;
	}

	#kds .cardTitle {
		@apply text-xl;
	}

	#kds .cardSubTitle {
		font-size: 0.6rem /* 12px */;
		line-height: 0.8rem /* 16px */;
	}

	#kds .itemsTimer {
		width: 35%;
	}
}

@media screen(md) {
	#kds .cardSubTitle {
		@apply text-xs;
	}
	#kds .itemsTimer {
		width: 30%;
	}
	#kds .appBarItem {
		@apply text-sm;
	}
}

@media screen(lg) {
	#kds .cardsContainer {
		columns: 3;
	}
}

@media screen(2xl) {
	#kds .cardsContainer {
		columns: 4;
	}
}
