#bottomNavigator .bg {
	background-color: var(--bg-navigatior);
}

#bottomNavigator .list {
	@apply h-14;
	background-color: var(--bg-navigatior);
}

#bottomNavigator .item {
	color: var(--second-color);
}

#bottomNavigator .item svg {
	@apply h-5;
}

#bottomNavigator .roundedItemBlock {
	background-color: var(--main-color);
}

#bottomNavigator .roundedItemBlock &:hover {
	filter: grayscale(0.5);
}

#bottomNavigator .roundedItem svg {
	@apply w-7 h-auto;
	color: #fff;
}

#bottomNavigator .item.Mui-selected {
	color: var(--second-color);
}

#bottomNavigator .item--focus.Mui-selected {
	color: var(--main-color);
}

#bottomNavigator .cartIcon {
	position: relative;
}

#bottomNavigator .cartLabel {
	@apply absolute flex items-center justify-center w-4 h-4 rounded-full text-xs -right-2 -top-1;
	background-color: var(--main-color);
	color: #fff;
}

.badgeItensPrice {
	display: flex;
}

.boxBadge {
	display: flex;
	justify-content: space-between;
	width: 800px;
	padding: 2vh 3vw;
}
