#modalLoading {
  @apply flex items-center justify-center h-full w-full px-6;
}

#modalLoading .pixTitle {
  color: var(--first-color);
}

#modalLoading .content {
  @apply rounded-3xl py-6 px-8 w-full flex items-center justify-center text-xl font-semibold text-center flex-col;
  background-color: var(--bg-color);
  max-width: 400px;
}

#modalLoading .loading {
  @apply mb-4 w-full;
  color: var(--main-color);
}
