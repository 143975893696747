#thermal {
	.boxFicha {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		/* padding: 0 30px; */
	}
	.typoNomeRestaurante {
		font-size: 30px;
		padding-bottom: 18px;
	}

	.typoTextoCupom {
		font-size: 20px;
		text-align: center;
		padding: 0 60px;
	}
	.qrCode {
		width: 200px;
		height: 250px;
	}

	.typoNumeroCupom {
		font-size: 20px;
	}
	.typoNumeroDoCupom {
		font-size: 24px;
	}

	.typoProduto {
		text-transform: uppercase;
		font-size: 24px;
		margin-top: 15px;
	}

	.typoPrecoImpresso{
		font-size: 18px;
		margin-bottom: 8px;

	}

	.typoDataHora {
		font-size: 18px;
	}

	.boxLogos {
		margin-top: 18px;
		display: flex;
		width: 320px;
		justify-content: space-between;
		align-items: center;
	}

	.logoZerofila {
		height: 55px;
	}
	.logoSicredi {
		height: 35px;
	}

	.linhaDeCorte {
		margin: 10px 0 ;
	}
}
