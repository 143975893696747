#menu {
	@apply pb-4;
	overflow-x: hidden;
}

#menu .banner {
	@apply px-4 pb-3;
}

#menu .bannerItem {
	@apply rounded-lg bg-cover bg-center w-full h-52 relative overflow-hidden flex justify-start items-end;
}

#menu .overlay {
	@apply absolute w-full h-full bg-black opacity-50 left-0 top-0;
}

#menu .bannerName {
	@apply text-xl font-semibold relative p-3 text-left;
	color: #fff;
	text-transform: none;
}

#menu .swiper-pagination {
	@apply flex items-center justify-center mt-4 relative;
}

#menu .swiper-pagination-bullet {
	@apply w-2 h-2 opacity-100;
	background: #dedee3;
}

#menu .swiper-pagination-bullet-active {
	@apply w-3 h-3;
	background: var(--first-color);
}

#menu .highlights {
	@apply pl-4 pb-3;
}

#menu .highlightsItem {
	@apply w-32;
}

#menu .highlightsItem:last-child {
	@apply mr-4;
}

#menu .highlightsBtn {
	@apply block p-0 text-left w-full;
	letter-spacing: normal;
	text-transform: none;
	font-size: initial;
}

#menu .highlightsImg {
	@apply w-32 h-32 rounded-lg bg-cover bg-center;
}

#menu .highlightsName {
	@apply text-sm font-normal mt-2;
	color: var(--first-color);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

}

#menu .fixedDepartments {
	@apply fixed top-0 left-0 w-full py-4 hidden opacity-0;
	background-color: var(--bg-navigatior);
	transition: all 0.6s;
	z-index: 2;
}

#menu .fixedDepartments.show {
	@apply opacity-100 block;
}

#menu .tabsList {
	@apply min-h-0;
	max-width: 767px;
	margin: 0 auto;
}

#menu .customTab {
	@apply font-semibold text-sm min-h-0 py-2 rounded-lg;
	color: var(--third-color);
}

#menu .customTab:first-child {
	@apply ml-4;
}

#menu .customTab:last-child {
	@apply mr-4;
}

#menu .customTab.active {
	background-color: var(--main-color);
	color: #fff;
}

#menu .productDepartment {
	@apply mb-2 text-xl font-semibold;
	color: var(--first-color);
}

@media (min-width: 767px) {
	#menu .bannerItem {
		@apply h-80;
	}

	#menu .highlights {
		@apply px-4;
	}
}
