#linksPage {
	@apply px-6 pb-6 pt-16 flex flex-col;
}

#linksPage .content {
	@apply flex-1;
}

#linksPage .imgClient {
	@apply w-32 h-32 rounded-full bg-cover bg-center mx-auto mb-4;
	box-shadow: 0px -1px 6px 8px var(--shadow-color);
}

#linksPage .btn {
	@apply w-full h-14 mb-4 text-lg font-semibold mx-auto block uppercase;
	max-width: 275px;
	box-shadow: none !important;
	border-radius: 0.5rem;
}

#linksPage .name {
	display: flex;
	justify-content: center;
	margin-bottom: 3.5vh;
  font-size: 1.30rem;
  font-weight: 600;
  color: rgb(32, 28, 28)
}

#linksPage .listSubBtn {
	@apply hidden;
}

#linksPage .listSubBtn.active {
	@apply block;
}

#linksPage .link {
	@apply w-full mb-3 text-lg font-semibold mx-auto block text-center uppercase;
	max-width: 275px;
}

#linksPage .footer {
	@apply mt-4;
}

#linksPage .footer .logo {
	@apply mx-auto w-32;
}
