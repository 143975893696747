#modalCoupon {
  @apply flex items-center justify-center px-6;
  height: 100vh;
}

#modalCoupon .MuiBackdrop-root {
  height: 100vh;
}

#modalCoupon .modalContent {
  @apply w-full py-6 px-8 rounded-3xl;
  background-color: var(--bg-color);
}

#modalCoupon .call {
  @apply text-center mb-5 font-medium;
  color: var(--first-color);
}

#modalCoupon .title {
  @apply text-center text-sm mb-2 font-medium;
  color: var(--first-color);
}

#modalCoupon .input {
  @apply mx-auto block;
  max-width: 200px;
}

#modalCoupon .modalBtn {
  @apply h-14 mt-4 mx-auto w-full block rounded-lg shadow-none text-sm text-white;
  background-color: var(--main-color);
  max-width: 185px;
}
