.placeholderBackground {
  position: relative;
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  color: white;
  background-color: var(--main-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholderInactive {
  background-color: #999999!important;
}

.badgesPlaceholder {
  display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}



.mainTitle {
  @apply text-2xl font-bold mt-4 mb-4;
  color: var(--main-color) !important;
}

.pointsText{
  @apply text-lg mt-4 mb-4;
  color: #656572;
}

.pointsDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  align-content: center;
}
.form-content{
  padding:0 1rem;
}


.cmb-3{
  margin-bottom: 0.75rem!important;
}