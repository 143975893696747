#paymentDelivery .container {
	@apply p-4;
}

#paymentDelivery .input {
	@apply mb-3;
}

#paymentDelivery .personalData {
	@apply p-4;
}

#paymentDelivery .personalData .desc {
	@apply text-sm text-center mt-3;
	color: var(--second-color);
}

#paymentDelivery .content {
	@apply p-4;
}

#paymentDelivery .head {
	@apply flex items-center mb-6;
}

#paymentDelivery .head .editBtn {
	@apply capitalize w-20 h-8 rounded-lg text-white;
	background-color: var(--main-color);
}

#paymentDelivery .delivery .deliveryHead {
	@apply font-semibold mb-4;
	color: var(--first-color);
	font-size: 18px;
}

#paymentDelivery .delivery .deliveryContent {
	border-top: 0;
}

#paymentDelivery .delivery .deliveryItem {
	@apply pt-5;
}

#paymentDelivery .delivery .deliveryListItem {
	@apply pb-2;
	border-bottom: 0.5px solid var(--second-color);
}

#paymentDelivery .delivery .itemContent {
	@apply flex items-center justify-between gap-4 cursor-pointer;
}

#paymentDelivery .delivery .itemContentBtn {
	@apply normal-case text-white w-16 h-6 rounded-md;
	background-color: var(--main-color);
}

#paymentDelivery .delivery .addBtn {
	@apply normal-case w-full h-8 rounded-none text-white mx-auto flex mt-3;
	background-color: var(--main-color);
	max-width: 234px;
}

#paymentDelivery .delivery .blockSelect {
	@apply mt-2;
}

#paymentDelivery .addCoupon {
	@apply normal-case h-8 rounded-md text-white max-w-xs w-full mt-4 mx-auto flex;
	color: var(--second-color);
	border: 1px solid var(--second-color);
}

#paymentDelivery .field {
	@apply mb-3;
}

#paymentDelivery .methodsIcon {
	@apply w-10 flex items-center justify-center;
}

#paymentDelivery .btnTroco {
	background-color: var(--main-color) !important;
	color: var(--second-color) !important;
	box-shadow: none !important;
	text-transform: initial !important;
}

#paymentDelivery .methodsIcon svg,
#paymentDelivery .optionalRadio {
	color: var(--second-color);
}

#paymentDelivery .methodsIcon--pix svg {
	width: 22px;
	height: auto;
}

#paymentDelivery .methodsIconWhite img {
	filter: brightness(0) invert(1);
}

#paymentDelivery .methodsText {
	@apply flex-1;
	color: var(--first-color);
}

#paymentDelivery .formRow {
	@apply flex gap-x-4;
}

#paymentDelivery .formValidate {
	@apply flex;
	flex: 2;
}

#paymentDelivery .formValidate .blockSelect {
	height: 56px;
}

#paymentDelivery .formValidate .blockSelect.error .select {
	border-color: red;
	color: red;
}

#paymentDelivery .formValidate .blockSelect.error .selectIcon {
	color: red;
}

#paymentDelivery .formValidate .blockSelect:last-child .select {
	@apply rounded-l-none;
}

#paymentDelivery .formValidate .blockSelect:first-child .select {
	@apply rounded-r-none border-r-0;
}

.containerRetirarLoja {
	padding: 3vw;
	border: 1px solid var(--second-color);
	border-radius: 8px;
	margin-bottom: 2vh;
}

.boxTypeRetirarLoja {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-items: center;
}

.infoContainer {
	display: flex;
	align-items: center;
}

.icon {
	font-size: 22px;
	margin-right: 2vw;
}
.iconMoto {
	font-size: 22px;
	margin-right: 2vw;
}
.iconLocation {
	font-size: 22px;
	margin-right: 2vw;
}

.iconClock {
	font-size: 25px;
	margin-right: 2vw;
}

.infoStatus {
	display: flex;
	font-size: 0.875rem;
	align-items: center;
}

.typoTypeRetirarLoja {
	font-weight: 500;
}

.mt {
	margin-top: 2vh;
}
