.headerBottomSheet {
  @apply relative flex items-center py-6 px-4;
}

.headerBottomSheet .title {
  @apply text-2xl font-semibold flex-1 text-center;
  color: var(--first-color);
}

.headerBottomSheet .btn {
  @apply relative w-10 h-10 min-w-0 shadow-none p-0;
  box-shadow: none !important;
  background-color: #918e8f !important;
}


