#myItems .content {
  @apply p-4;
}

#myItems .infos {
  @apply pb-8 border-b;
  border-color: var(--second-color);
}

#myItems .info {
  @apply text-xl;
  color: var(--first-color);
}

#myItems .callList {
  @apply mx-auto text-center ;
  max-width: 225px;
  color: var(--first-color);
}

#myItems .list {
  @apply pt-2;
}

#myItems .item {
  @apply mb-4 flex items-center gap-2 text-left w-full p-0;
  text-transform: none;
  letter-spacing: normal;
  font-size: initial;
  min-width: auto;
}

#myItems .itemImg {
  @apply w-14 h-14 bg-cover bg-center rounded-md;
}

#myItems .itemContent {
  @apply flex-1;
}

#myItems .itemPrice {
  @apply text-xl font-semibold;
  color: var(--first-color);
}

#myItems .itemPrice span {
  @apply text-sm;
}

#myItems .itemTitle,
#myItems .itemQtd {
  color: var(--second-color);
}

#myItems .itemOptions {
  @apply text-xs;
  color: var(--second-color);
}

#myItems .boxQrCode {
  @apply p-2 rounded-md ml-2;
  background-color: #fff;
}

#myItems .boxQrCode svg {
  @apply w-10 h-10;
}
