#product .btnDown {
	@apply absolute w-10 h-10 min-w-0 shadow-none p-0 z-10 left-4 top-4;
	box-shadow: none !important;
	background-color: #918e8f !important;
}

#product .bannerItem {
	@apply bg-cover bg-center w-full h-80 rounded-b-2xl;
}

#product .swiper-button-next:after,
#product .swiper-button-prev:after {
	@apply text-xl;
	color: #fff;
}

#product [data-rsbs-header] {
	position: absolute;
	z-index: 99;
	width: 100%;
	box-shadow: none !important;
}

#product [data-rsbs-header]:before {
	background-color: #fff;
}

#product .title {
	@apply text-3xl font-semibold mb-2;
	color: var(--first-color);
}

#product .tags {
	@apply flex mb-2;
}

#product .tagItem {
	@apply border rounded-lg px-2 py-1 text-xs mr-2;
}

#product .descriptionTitle {
	@apply text-lg font-semibold mb-1;
	color: var(--first-color);
}

#product .description {
	white-space: pre-wrap;
	word-wrap: break-word;

}

#product .descriptionAndPrice {
	@apply w-full justify-between flex;
}

#product .description {
	@apply text-sm mb-1;
	color: var(--second-color);
}

#product .optional {
	@apply mt-4;
}

#product .optionalHeader {
	@apply flex items-center py-2 px-4 rounded-t-lg;
	background-color: #dddddd;
	min-height: 56px;
}

#product .optionalHeaderContent {
	@apply flex-1 pr-3;
}

#product .optionalHeaderTitle {
	@apply font-semibold;
	color: #656572;
}

#product .optionalHeaderSelection {
	@apply text-xs mt-1;
	color: #656572;
}

#product .optionalHeaderRequired {
	@apply py-1 px-3 uppercase font-semibold rounded-md;
	background-color: #8d8989;
	color: #fff;
	font-size: 10px;
}

#product .optionalBody {
	@apply border p-4;
	border-color: #dddddd;
}

#product .optionalBodyItem {
	@apply border-b p-2 flex items-center justify-between cursor-pointer;
	border-color: #dddddd;
}

#product .optionalBodyItem:last-child {
	@apply border-0 pb-0;
}

#product .optionalThumb {
	@apply w-12 h-12 bg-cover bg-center rounded-lg relative overflow-hidden;
}

#product .optionalRadio {
	color: var(--second-color);
}

#product .optionalBodyDesc {
	color: var(--second-color);
}

#product .optionalBodyPrice {
	@apply text-sm;
	color: var(--second-color);
}

#product .optionalBodyPrice--withPromo {
	text-decoration: line-through;
	opacity: 0.6;
}

#product .optionalBodyDetail {
	@apply text-xs block pr-1;
	color: var(--second-color);
}

#product .optionalBtns {
	@apply flex items-center gap-2;
}

#product .optionalBtn {
	@apply p-1 min-w-0 w-8 h-6;
	color: #656572;
	background-color: #ddd;
}

#product .optionalBtn svg {
	@apply text-base;
}

#product .optionalQtdTxt {
	@apply w-6 text-center;
	color: var(--second-color);
}

#product .optionalObs fieldset {
	@apply rounded-t-none;
}

#product [data-rsbs-footer] {
	box-shadow: 0px -6px 16px var(--shadow-color);
}

#product .footer {
	@apply flex justify-between items-center gap-4 py-6 px-4;
	box-shadow: 0px -6px 16px 0px var(--shadow-color);
	border-top: 1px solid var(--bg-icon);
}

#product .footerBtn {
	@apply flex justify-between items-center h-14 px-4 rounded-lg shadow-none;
	background: var(--main-color);
}

#product .footerQtdBtn {
	@apply p-1 min-w-0;
	color: #fff;
}

#product .footerQtdBtn svg {
	@apply text-base;
}

#product .footerQtdTxt {
	@apply w-6 text-center font-semibold;
	color: #fff;
}

#product .footerValue {
	@apply text-lg font-semibold;
	color: #fff;
}

#product .checkboxContainer {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 18px;
	width: 18px;
	margin: 12px;
}

#product .checkboxContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

#product .checkboxCheckmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: transparent;
	border: 2px solid var(--second-color);
	border-radius: 3px;
}

#product .checkboxContainer input:checked ~ .checkboxCheckmark {
	background-color: var(--second-color);
}

#product .checkboxCheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

#product .checkboxContainer input:checked ~ .checkboxCheckmark:after {
	display: block;
}

#product .checkboxContainer .checkboxCheckmark:after {
	left: 3px;
	top: 0px;
	width: 7px;
	height: 12px;
	border: solid var(--bg-color);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

/* The container */
.radioContainer {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 20px;
	width: 20px;
	margin: 12px;
}

/* Hide the browser's default radio button */
.radioContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 2px solid var(--second-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .radioCheckmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radioCheckmark:after {
	top: 3px;
	left: 3px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: var(--second-color);
}
