#informations .banner {
  @apply h-80 bg-cover bg-center relative overflow-hidden flex items-center justify-center rounded-b-2xl;
}

#informations .banner::before {
  content: '';
  @apply absolute w-full h-full bg-black opacity-50 left-0 top-0;
}

#informations .bannerName {
  @apply text-2xl font-semibold relative;
  color: #fff;
}

#informations .btnDown {
  @apply absolute w-10 h-10 min-w-0 shadow-none p-0 z-10 left-4 top-4;
  box-shadow: none !important;
  background-color: #918e8f !important;
}

#informations [data-rsbs-header] {
  position: absolute;
  z-index: 99;
  width: 100%;
}

#informations [data-rsbs-header]:before {
  background-color: #fff;
}

#informations .content {
  @apply p-4;
}

#informations .card {
  @apply p-4 rounded-2xl;
  box-shadow: 10px 24px 54px var(--shadow-color);
}

#informations .list {
  @apply relative;
}

#informations .list:before {
  @apply absolute h-5/6 left-5 top-4;
  content: '';
  border-left: 1px dashed #dddddd;
}

#informations .listItem {
  @apply grid gap-x-4 mb-4 relative;
  grid-template-columns: 40px 1fr;
}

#informations .listIcon {
  @apply w-10 h-10 rounded-xl flex items-center justify-center;
  background: #918e8f;
}

#informations .listIcon svg {
  color: #fff;
}

#informations .listTitle {
  @apply mb-1 font-semibold;
  color: var(--first-color);
}

#informations .listDesc {
  @apply text-sm;
  color: var(--second-color);
}
