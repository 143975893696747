#busca .buscaScrollBox {
	/* width: 100vw; */
	overflow-x: auto;
	display: flex;
	gap: 0.6rem;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#busca .bannerBox {
	@apply rounded-lg bg-cover bg-center w-full relative overflow-hidden flex justify-start items-end;
	height: 20vh;
}

#busca .swiper-pagination {
	@apply flex items-center justify-center mt-4 relative;
}

#busca .swiper-pagination-bullet {
	@apply w-2 h-2 opacity-100;
	background: #dedee3;
}

#busca .swiper-pagination-bullet-active {
	@apply w-3 h-3;
	background: var(--first-color);
}

#busca .optionBox {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 1rem;
	text-align: center;
}

#busca .restaurantSearchItem {
	display: grid;
	/* width: 100vw; */
	grid-template-columns: 1fr 1fr 1fr 1fr;
	justify-items: center;
}

#busca .restaurantItem {
	overflow-x: auto;
	display: flex;
	gap: 4vw;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}
#busca .productCardStyle {
	color: black;
	width: max-content;
	position: relative;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#busca .buttonResFilter {
	border: 1px solid #d8d9db;
	padding: .5rem 1rem;
	margin-top: 1rem;
	border-radius: 5rem;
	font-size: .75rem;
	border: 1px 1px 1px 1px;
	opacity: 0px;
}

#busca .productContainer {
	display: flex;
	overflow-x: auto;
	/* width: 100vw; */
	gap: 1rem;
	padding-left: 1.5vw;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#busca .productTitle {
	-webkit-line-clamp: 2; /* Number of lines to show */
	line-clamp: 2; /* Same as -webkit-line-clamp for other browsers */
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
	color: #56585c;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis !important;
	display: inline-block;
	width: 7.5625rem;
	font-size: 0.75rem;
	max-height: 2rem;
	line-height: 1rem;
	color: #56585c;
	text-align: left;
	margin-top: 0.4rem;
}

#busca .productPrice {
	font-size: 0.75rem;
	text-align: justify;
}

#busca .restaurantInternalBox {
	width: 7.5625rem;
	height: 5.625rem;
	top: 228px;
	left: 17px;
	border-radius: 10px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#busca .restaurantTyph {
	color: #56585c;
	font-size: 0.875rem;
	text-align: left;
	/* margin-left: 5vw; */
	margin-bottom: 1rem;
}

#busca .categoriesTyph {
	color: #56585c;
	font-size: 0.875rem;
	text-align: left;
	/* margin-left: 5vw; */
}

#busca .restaurantNameTyph {
	display: flex;
	align-items: center;
	color: #56585c;
	font-size: 0.875rem;
	text-align: left;
	/* margin-left: 5vw; */
	margin-bottom: 0.5rem;
}

#busca .circleBox {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	margin-bottom: 0.5rem;
	border: 1px solid #f9f9f9;
	border-radius: 100%;
/* 
	width: 14.7vw;
	height: 14.7vw; */
}

#busca .MuiInputBase-root {
	border: 0px !important;
	padding-right: 0.1rem;
}

#busca .MuiOutlinedInput-notchedOutline {
	border: 0px !important;
}
