#fila {
	.cardContainer {
		margin: 10;
		padding: 1.5vw;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		align-items: flex-start;
	}
	.buttonPages {
		padding: 0.5rem 1.5rem;
		border-radius: 0.5rem;
		margin-left: 0.75rem;
		text-transform: uppercase;
		border: none;
		margin: 0 5px;
	}

	.box-container {
		border-radius: 1rem;
		background-color: #fff;
	}

	.box-flex-1 {
		flex: 1;
	}

	.typography {
		text-align: center;
		line-height: 40px;
		color: #323236;
		font-size: 14px;
	}

	.btnAcao {
		margin-top: 1.5rem;
		background: transparent;
		color: #3e3e3e;
		border: 1px solid #c6c6c6;
		width: 100%;
	}

	.boxLabelPrioritario {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		word-break: break-word;
		text-align: center;
	}

	.boxPrioritario {
		background: linear-gradient(45deg, green, darkgreen);
		border-radius: 8px;
		box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
		padding: 8px;
	}

	.typoTagPrioritario {
		text-align: center;
		color: #fff;
		font-size: 0.875rem;
	}
	.boxClientList {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.typoClientList {
		text-align: center;
		color: #323236;
		font-size: 0.875rem;
	}
	.typoClientListNumeroPessoas {
		text-align: start;
		color: #323236;
		font-size: 0.875rem;
	}
	.boxHeader {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		padding: 2vh 3vw;
		background: #fff;

	}
	.boxIconesPages {
		display: flex;
		justify-content: center;
		padding: 0 8vw;
		flex: 1;

		

	}
	.boxBtnSair {
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}
}
