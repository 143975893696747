#payment .container {
  @apply p-4;
}

#payment .title {
  @apply mb-4;
  color: var(--first-color);
}

#payment .form {
  @apply mb-4;
}

#payment .field {
  @apply mb-3;
}

#payment .methodsItem {
  @apply mb-4;
}

#payment .methodsHead {
  @apply h-14 rounded-xl mb-4 flex items-center gap-x-4;
  box-shadow: 5px 5px 14px var(--shadow-color);
}

#payment .methodsIcon {
  @apply w-10 flex items-center justify-center;
}

#payment .methodsIcon svg,
#payment .optionalRadio {
  color: var(--second-color);
}

#payment .methodsIcon--pix svg {
  width: 22px;
  height: auto;
}

#payment .checkboxLabel {
  @apply mr-0;
}

#payment .checkboxLabel span {
  @apply text-sm;
  color: var(--second-color);
}

#payment .methodsIcon--pix svg {
  width: 24px;
  height: auto;
}

#payment .methodsText {
  @apply flex-1;
  color: var(--first-color);
}

#payment .formRow {
  @apply flex gap-x-4;
}

#payment .formValidate {
  @apply flex;
  flex: 2;
}

#payment .formValidate .blockSelect {
  height: 56px;
}

#payment .formValidate .blockSelect.error .select {
  border-color: red;
  color: red;
}

#payment .formValidate .blockSelect.error .selectIcon {
  color: red;
}

#payment .formValidate .blockSelect:last-child .select {
  @apply rounded-l-none;
}

#payment .formValidate .blockSelect:first-child .select {
  @apply rounded-r-none border-r-0;
}
