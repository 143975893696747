#ratingComp .listStars {
  @apply flex justify-center gap-2 mb-4;
}

#ratingComp .listStars svg {
  color: #d4d4d4;
}

#ratingComp .listStars .active svg {
  color: #fdc200;
}

#ratingComp .optionalObs fieldset {
  @apply px-4 py-5 rounded-xl;
  border-color: rgba(0, 0, 0, 0.09) !important;
  background-color: rgba(191, 190, 190, 0.1);
}

#ratingComp .optionalObs textarea {
  @apply text-sm;
}

#ratingComp .succesMsg {
  @apply text-xl text-center mt-2 font-semibold;
  color: var(--first-color);
}

#ratingComp .succesMsg.small {
  @apply text-base;
}

#ratingComp .btnLink {
  @apply w-44 h-10 mt-4 text-base mx-auto block;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}

#ratingComp .btnLink.Mui-disabled {
  @apply opacity-50;
}

#ratingComp .title {
  @apply font-semibold text-center mb-2;
  color: var(--first-color);
}
