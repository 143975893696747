#eventos .headerEventos {
	background: #353535;
	width: 100%;
	padding: 2vh 3vw;
	box-shadow: 0 0 20px 10px rgb(243 243 243 / 80%);
	display: flex;
	justify-content: center;
}

#eventos .logoBotaoHeader {
	height: 5vh;
	width: 100%;
	gap: 3vw;
	display: flex;
	max-width: 1176px;
	justify-content: space-between;
	padding: 0 1rem;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
	padding: 0px !important;
}

#eventos .imgLogo {
	border-radius: 0.5rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	max-width: 35%;
}

.botaoLogin {
	display: flex;
	align-items: center;
	border: 1px solid #fff;
	border-radius: 0.5rem;
	padding: 1vw;
	font-size: 0.875rem;
	color: #fff;
	text-transform: capitalize;
}

.logoTypoEmpresa {
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 1.125rem;
	width: 20%;
}

#eventos .campoPesquisa {
	display: flex;
	border-radius: 0.5rem;
	justify-content: center;
	background-color: #ffffff;
	border: 1px solid rgb(189, 194, 201);
	box-shadow: rgba(25, 31, 40, 0.15) 0px 1px 2px 0px;
	width: 80%;
}

#eventos .eventsBanner {
	max-width: 1176px;
	margin-top: 2vh;
}

#eventos .eventsBannerBoxImg {
	padding: 0 1vw;
	height: 42vw;
}

#eventos .eventsBannerImg {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
}

#eventos .boxEventos {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1rem;
	max-width: 1176px;
	position: relative;
	/* height: 100%; */
}

#eventos .titulo {
	font-size: 1.125rem;
	margin: 1vh 0 1vh;
	color: #191f28;
}

#eventos .conteinerCardEvents {
	width: 100%;
}

#eventos .conteinerCards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
}

#eventos .cardEvents {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0.5rem;
}

#eventos .imgEventsCard {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
}

#eventos .boxDetailsEventCard {
	display: flex;
	gap: 0.2rem;
	margin: 1vh 0 0.6vh 0;
}

#eventos .detailsEventCard {
	font-size: 0.75rem;
}

#eventos .tituloEvento {
	font-size: 0.875rem;
	color: #191f28;
	margin-bottom: 0.6vh;
	text-transform: capitalize;
}

#eventos .descricaoEventCard {
	font-size: 0.75rem;
	color: #4c576c;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	text-transform: capitalize;
}

#eventos .iconPag {
	font-size: 2rem;
	color: #191f28;
}

#eventos .buttonContatoPolitica {
	border-radius: 0.5vh;
	height: 5vh;
	box-shadow: none;
}

#eventos .eventsIconWhats {
	display: flex;
	position: fixed;
	z-index: 1;
	bottom: 4vh;
	right: 3vw;
	justify-content: flex-end;
}

#eventos .boxEventsIconWhats {
	background: #fff;
	border-radius: 50%;
	box-shadow: 0px 2px 20px #ababab;
}

#eventos .boxConatinerDetails {
	display: flex;
	flex-direction: row;
	width: 100vw;
	padding: 2rem;
	max-width: 1100px;
	position: relative;
	justify-content: space-between;
}

#eventos .boxDetails {
	width: 35vw;
}

#eventos .detailsDesktop {
	width: 100%;
	display: flex;
	justify-content: center;
	background: #f5f5f5;
}
#eventos .detailsEvent {
	padding-right: 2.5vw;
	width: 100%;
}

#eventos .tituloEventoDetails {
	font-size: 1.75rem;
	margin-bottom: 1.5vh;
	color: #191f28;
}

#eventos .tituloDescricaoDetails {
	font-size: 1.25rem;
	margin-bottom: 1vh;
	color: #191f28;
}

#eventos .textoDetails {
	margin-bottom: 1vh;
	color: #191f28;
	font-size: 0.875rem;
}

#eventos .botaoIncricao {
	text-transform: capitalize;
	color: white;
	width: 30vw;
	border-radius: 0.625rem;
}

#eventos .containerEventsPay {
	width: 100%;
	text-align: left;
	background-color: rgb(245, 247, 248);
	color: rgb(25, 31, 40);
	border-bottom: 1px solid rgb(245, 247, 248);
}

#eventos .boxParticipantePag {
	width: 100%;
	max-width: 1136px;
	margin: 0px auto;
	padding: 32px 0px;
	display: flex;
	justify-content: center;
	gap: 2vw;
}

#eventos .boxTicket {
	display: flex;
	flex-direction: column;
	padding: 24px;
	gap: 8px;
	box-shadow: rgba(25, 31, 40, 0.15) 0px 1px 2px 0px;
	border-radius: 8px;
	background-color: rgb(255, 255, 255);
}

#eventos .cupomEventsPay {
	width: 30%;
	margin-top: 1.5vh;
}

#eventos .boxInfoParticipante {
	width: 55vw;
	height: auto;
	background: #fbfbfb;
	margin-bottom: 2vh;
	display: flex;
	flex-direction: column;
	justify-content: start;
}

#eventos .infoParticipantePag {
	border-radius: 8px;
	width: 60%;
	height: 100%;
}

#eventos .boxPay {
	display: flex;
	flex-direction: column;
	padding: 24px;
	margin-top: 1.5vh;
	background-color: rgb(255, 255, 255);
	border-radius: 8px;
	box-shadow: rgba(25, 31, 40, 0.15) 0px 1px 2px 0px;
	height: 100%;
}

#eventos .boxCupomPayment {
	align-items: center;
	max-height: 64px;
	padding: 0px 16px;
	margin-bottom: 16px;
}

#eventos .inputCupom {
	font-size: 14px;
	border-radius: 0.5rem;
}
#eventos .subBoxCupom {
	display: flex;
	height: 40px;
	gap: 24px;
	margin-bottom: 0px;
}

#eventos .buttonCupom {
	border-radius: 8px;
	outline: none;
	border-width: 1px;
	font-size: 0.75rem;
	line-height: 16px;
	align-items: center;
	justify-content: center;
	min-width: fit-content;
	width: max-content;
	border-style: solid;
	padding: 12px 16px;
	text-transform: capitalize;
}

#eventos .boxIngresso {
	box-shadow: rgb(221, 224, 228) 0px 6px 24px;
	border-radius: 12px;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 335px;
}
#eventos .boxDate {
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 335px;
}

.boxTickes {
	padding: 8px 16px;
	display: flex;

	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	border-width: 0px 0px 1px;
	border-style: solid;
	border-color: rgb(221, 224, 228);
	flex-direction: row;
}

#eventos .ButtonMenos {
	padding: 0vw;
	min-width: 32px;
	height: 32px;
	text-align: center;
	border-radius: 8px;
}

#eventos .ButtonMais {
	padding: 0vw;
	min-width: 32px;
	height: 32px;
	background: #d9d9d9;
	text-align: center;
	color: #fff;
	border-radius: 8px;
}
#eventos .paymentBoxPrice {
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
}

#eventos .paymentBoxInfosPrice {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	margin-top: 0.5vh;
}

#eventos .paymentInfos {
	display: flex;
	flex-direction: row;
	align-items: end;
	gap: 4px;
}

#eventos .boxLogin {
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100%;
}
#eventos .boxDadosLogin {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	padding: 2vw;
	background-color: white;
}

#eventos .modalWarning {
	font-size: 1.25rem;
	color: #2e2e2e;
}

#eventos .ticketEvents {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	flex-direction: column;
}
#eventos .ticketEventsBox {
	width: 100vw;
	max-width: 1176px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 1rem;

}

.css-1bqjbe6-MuiGrid-root > .MuiGrid-item {
	padding-left: 0px;
}

#eventos .bannerIngresso {
	height: 15vh;
}

.bannerEventoInterno {
	display: flex;
	justify-content: center;
	width: 100%;
	min-width: 320px;
	background-color: rgb(255, 255, 255);
	position: relative;
	height: 64vh;
	max-height: 336px;
	min-height: 336px;
}

.bannerEventoInterno::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(8px);
	background-color: rgba(0, 0, 0, 0.7);
}

.boxEventsBanner {
	display: flex;
	width: 100%;
	max-width: 1100px;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	padding: 0px 32px;
	margin-top: -8px;
	height: 100%;
}

.boxEventsTitle {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	width: 100%;
	min-width: 320px;
	background-color: transparent;
	position: relative;
}

.boxEventsTitleBanner {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px;
	max-width: 1100px;
	position: relative;
}

.subBannerEvento {
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 100%;
	aspect-ratio: 16 / 9;
	width: 100%;
	max-width: 540px;
	max-height: 280px;
	margin: 0px auto;
	border-radius: 12px;
	box-shadow: rgba(25, 31, 40, 0.2) 0px 20px 36px 0px;
	object-fit: cover;
}

.boxShare {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 0 2rem;
	max-width: 1100px;
}

.BnSharetLink {
	border-radius: 50%;
	background: #fff;
	margin: -3.6vh 0;
	height: 4rem;
	width: 4rem;
	box-shadow: 0px 2px 20px #ababab;
}
.BnSharetLink:hover {
	background: #fff;
}
.custom-swiper-slide {
	width: 100vw !important;
}

.imagemSubBanner {
	height: 100%;
	width: 100%;

	border-radius: 12px;
}
.eventTitleBanner {
	font-size: 28px;
	color: rgb(255, 255, 255);
	margin-top: 8px;
	margin-bottom: 16px;
	word-break: break-word;
	line-height: 40px;
	letter-spacing: -0.05rem;
}

.boxDetailsEventDate {
	display: flex;
	align-items: start;
}

.boxDetailsEventLocal {
	display: flex;
	align-items: start;
	margin-top: 1vh;
}

.boxDetailsEventBtnShare {
	display: flex;
	align-items: center;
	width: 10rem;
	height: 2.5rem;
	border-radius: 3vw;
	justify-content: center;
	margin-top: 1vh;
}

.iconEvents {
	margin-right: 8px;
	fill: #191f28;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
}

.css-qywfm8-MuiDivider-wrapper {
	display: none;
	padding-left: 0;
	padding-right: 0;
}

.detailsInfos {
	display: none;
}

.fotterEvents {
	display: grid;
	padding: 0 3vw;
	grid-template-columns: 1fr 1fr;
}

.btnFooterEvents {
	padding: 0;
	color: #fff;
	text-transform: capitalize;
	font-size: 0.875rem;
	padding: 0 5vw;
}
.btnNextDetails {
	margin-top: 1.5vh;
	border-radius: 8px;
	border-color: transparent;
	border-width: 1px;
	border-style: solid;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;
	min-width: fit-content;
	width: max-content;
	color: #fff;
	padding: 8px 16px;
	text-transform: capitalize;
}
.btnRegister {
	margin-top: 1.5vh;
	border-radius: 8px;
	border-color: transparent;
	border-width: 1px;
	border-style: solid;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;

	width: 100%;
	color: #fff;
	padding: 8px 16px;
	text-transform: capitalize;
}
.btnRegisterCancel {
	margin-top: 1.5vh;
	border-radius: 8px;
	border-color: transparent;
	border-width: 1px;
	border-style: solid;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 8px 16px;
	text-transform: capitalize;
}
.btnNewRegister {
	margin-top: 1.5vh;
	border-radius: 8px;
	border-color: transparent;
	border-width: 1px;
	border-style: solid;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;
	width: 70%;
	color: #fff;
	padding: 8px 16px;
	text-transform: capitalize;
}
.btnBackDetails {
	margin-top: 1.5vh;
	border-radius: 8px;
	border-width: 1px;
	border-style: solid;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;
	min-width: fit-content;
	width: max-content;
	padding: 8px 16px;
	text-transform: capitalize;
}

#eventos .swiper-prev,
.swiper-next {
	color: white;
	font-size: 3rem;
}

#eventos .swiperModalPhoto {
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

#eventos .boxEventsDate {
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	width: 8rem;
	height: 3rem;
	align-items: center;
	justify-content: center;
}


#eventos .conteinerEventsDate {
	display: flex;
	overflow-y: auto;
	gap: 0.8rem;
}

@media (max-width: 830px) {
	#eventos {
		.headerEventos {
			flex-direction: column;
		}
		.logoBotaoHeader {
			padding: 0;
			align-items: center;
		}
		.boxDetailsEvent {
			display: none;
		}
		.botaoLogin {
			padding: 1.5vw;
		}
		.bannerEventoInterno {
			height: 50vh;
			max-height: 280px;
			min-height: 200px;
			margin-bottom: 0px;
		}
		.boxEventsBanner {
			padding: 0px;
			margin-top: 0px;
			height: 100%;
			flex-direction: column;
			align-items: center;
		}
		.subBannerEvento {
			border-radius: 0px;
		}
		.boxIngresso {
			width: 100%;
			margin: 2vh 0 4vh 0;
		}
		.detailsEvent {
			padding: 0vw;
		}
		.detailsInfos {
			display: contents;
		}
		.textoDetails {
			flex-direction: column-reverse;
			align-items: center;
		}
		.infoParticipantePag,
		.cupomEventsPay {
			width: 90%;
		}
		.boxParticipantePag {
			flex-direction: column-reverse;
			align-items: center;
		}
		.conteinerCards {
			grid-template-columns: repeat(2, 1fr);
		}
		.campoPesquisa {
			margin: 2vh 0 0;
			height: 5vh;
			width: 100%;
		}
		.logoTypoEmpresa {
			width: 100%;
		}
		.boxDadosLogin {
			width: 100%;
			height: 100%;
			padding: 1rem;
		}
		.eventsBanner {
			max-width: 100%;
		}
		.ticketEventsBox {
			padding: 0 2rem;
		}
		.eventsIconWhats {
			bottom: 22vh;
		}
		.swiper-prev,
		.swiper-next {
			font-size: 2rem;
		}
		.swiperModalPhoto {
			height: 40vh;
		}
		.boxDate{
			width: 100%;
		}
	}
}

@media (min-width: 830px) {
	#evento {
		.eventsBannerBoxImg {
			max-width: 1176px;
			height: 45vh;
		}
	}
}
