#autoatendimento {
	.productsOverflow {
		-ms-overflow-style: none !important;
		scrollbar-width: none !important;
		scrollbar-color: transparent !important;
		scrollbar-darkshadow-color: transparent !important;
		scrollbar-base-color: transparent !important;
		width: 22vw;
		overflow-y: auto;
		padding: 0.2rem;
		display: flex;
		flex-direction: column;
	}

	.productsOverflowGrid {
		-ms-overflow-style: none !important;
		scrollbar-width: none !important;
		scrollbar-color: transparent !important;
		scrollbar-darkshadow-color: transparent !important;
		scrollbar-base-color: transparent !important;
		/* width: 72vw; */
		padding: 0 1vw;
		padding-bottom: 2vh;
		overflow-y: auto;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1.5vh 2vw;
		grid-auto-rows: max-content;
	}
	.boxBtnComandaPedido {
		display: grid;
		justify-content: space-evenly;
		position: absolute;
		width: 100vw;
	}
	.boxBotaoIniciar {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 46vw;
		height: 15vh;
		bottom: 10.5vh;
		border-radius: 1.3rem;
		background-color: rgba(255, 255, 255);
		z-index: 9999;
	}
	.iconTouch {
		width: 8vw;
		height: auto;
	}
	.botaoIniciarConteudo {
		font-size: 1.8rem;
		text-align: center;
		width: 70%;
		color: #3e3e3e;
		margin-top: 0.8vh;
	}
	.logo {
		position: fixed;
		width: 7.6vh;
		height: 7.6vh;
		border-radius: 100%;
		top: 2.8vh;
	}
	.bannerFundo {
		width: 100vw;
		height: 8vh;
	}
	.BoxBanner {
		width: 94vw;
		height: 25vh;
		margin-top: 5.5vh;
		margin-left: 3vw;
	}
	.containerProdutos {
		display: flex;
		justify-content: center;
		width: 100vw;
		gap: 2vw;
	}
	.containerProdutos {
		display: flex;
		justify-content: center;
		width: 100vw;
		gap: 2vw;
	}
	.boxDepartamento {
		width: 100%;
		height: 16vh;
		border-radius: 1rem;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.boxcardProduto {
		width: 100%;
		height: 16vh;
		border-radius: 1rem;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
	}
	.cardProduto {
		width: 100%;
		height: 100%;
		display: flex;
		border-radius: 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.boxCardImg {
		width: 100%;
		height: 11vh;
		border-radius: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cardImg {
		width: 100%;
		height: 100%;
		border-radius: 1rem 1rem 0 0;
	}
	.boxCardImgRelatedProducts {
		width: 100%;
		height: 11vh;
		border-radius: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cardImgRelatedProducts {
		width: 100%;
		height: 100%;
		border-radius: 1rem 1rem 0 0;
	}
	.boxConteudo {
		width: 100%;
		height: 5vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.nomeProduto {
		width: 94%;
		text-align: center;
		font-size: 0.9rem;
		line-height: 1.4rem;
	}
	.nomeProdutoRelatedProducts {
		width: 94%;
		text-align: center;
		font-size: 0.9rem;
		line-height: 1.4rem;
	}
	.descricaoProduto {
		display: none;
	}
	.boxConteudoPrecoDescricao {
		display: flex;
		gap: 0.4rem;
		justify-content: center;
		align-items: center;
	}
	.boxDetailsProduct {
		height: 90vh;
		overflow-y: auto;
	}
	.bannerInterno {
		width: 100vw;
		height: 33vh;
		border-radius: 2rem 2rem 0rem 0rem;
	}
	.boxDetailsProductTitulo {
		width: 94vw;
		height: 2vh;
		margin-top: 3vh;
		margin-left: 3vw; /* 2px */
		margin-right: 3vw;
	}
	.detailsProductTitulo {
		font-size: 2rem;
	}
	.detailsDescricao {
		width: 94vw;
		margin-top: 1vh;
		margin-left: 3vw;
		margin-right: 3vw;
		text-align: justify;
	}
	.detailsProductDescricao {
		font-size: 1.11rem;
	}
	.boxOpicional {
		width: 94vw;
		margin-top: 1.5vh;
		margin-left: 3vw;
		margin-right: 3vw;
		border-radius: 0.5rem;
		margin-bottom: 3vh;
		border: 1px solid #dddddd;
	}
	.opcionais {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 6vh;
	}
	.boxTitulo {
		height: 5vh;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #dddddd;
		border-radius: 0.5rem 0.5rem 0 0;
		padding: 0 2vw;
	}
	.opcaoFontSize {
		font-size: 1.2rem;
	}
	.opcionalObrigatorio {
		display: flex;
		align-items: center;
		height: 2vh;
		padding-left: 1.3vw;
		padding-right: 1.3vw;
		border-radius: 0.5rem;
	}
	.typoObrigatorio {
		font-size: 1rem;
	}
	.botaoAdicionar {
		width: 58vw;
	}
	.logoClientInformation {
		height: 8vh;
		margin-top: 11vh;
	}
	.confirmBtnMoreInfo {
		position: static;
		margin: 0 0.5rem;
		padding: 2vw;
		height: 5vh;
		border-radius: 0.5rem;
		font-size: 2rem;
		text-transform: none;
	}
	.returnBtnMoreInfo {
		position: fixed;
		top: 88vh;
		margin: auto;
		font-size: 2rem;
		margin-bottom: 1vh;
	}
	.botao {
		display: flex;
		justify-content: space-between;
		border-radius: 0.5rem;
		padding: 0 1vw;
	}
	.autoLogoButton {
		background-color: white;
		width: 65vw;
		padding: 1.2vh 1.2vw;
		border-radius: 1vh;
	}
	.tabletPriceTag {
		display: none;
	}
	.totemPriceTag {
		display: block;
		font-size: 2rem;
	}
	.totemOptionals {
		display: block;
	}
	.tabletOptionals {
		display: none;
	}
	.btnAddTablet {
		display: none;
	}
	.botaoInternoMaisMenos {
		width: 30vw;
		border-radius: 0.5rem;
		height: 3.6vh;
	}
	.descricaoProdutoCarrinho {
		font-size: 0.95rem;
		line-height: 1.3rem;
		max-height: 2.3rem;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		margin-bottom: 0.2vh;
		text-align: justify;
	}

	.totemPrecoCarrinho {
		font-size: 1.4rem;
	}
	.boxCarrinho {
		box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.4), 0px 1px 8px 0px rgba(0, 0, 0, 0.3);
		position: fixed;
		width: 100vw;
		bottom: 0vh;
		right: 0vh;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.botaoCarrinho {
		width: 100%;
		height: 5.1vh;
		border-radius: 0.5rem;
		font-size: 1.25rem;
		box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
		text-transform: uppercase;
	}
	.totalItem {
		font-size: 1.5625rem;
		margin-right: 0.7rem;
	}

	.total {
		font-size: 1.5625rem;
	}
	.valor {
		font-size: 1.875rem;
	}
	.item {
		font-size: 1.25rem;
	}
	.boxCarrinhoAuto {
		display: flex;
		padding: 1vh 3vw;
		justify-content: center;
		border-bottom: 1px solid #e0e0e0;
		justify-content: space-between;
		align-items: center;
	}
	.boxConteudoCarrinho {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 0 2vw;
		width: 45vw;
	}
	.imgProduto {
		width: 18vw;
		height: 8.5vh;
		border-radius: 1rem;
	}
	.totemTituloCarrinho {
		font-size: 1.2rem;
	}
	.totemTituloCarrinho {
		font-size: 1.2rem;
	}
	.btnCarrinhoMaisMenos {
		background-color: #f5f5f5;
		border-radius: 0.5rem;
		height: 2.8vh;
		display: flex;
		align-items: center;
	}
	.boxLevar {
		width: 28vw;
		height: 20vh;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.circuloImg {
		background-color: white;
		width: 7vh;
		height: 7vh;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.imgConsumir {
		width: 6vh;
		height: auto;
	}
	.escolhaOpcao {
		font-size: 1.4rem;
	}
	.escolhaMinMax {
		font-size: 1.1rem;
	}
	.formaPag {
		height: 7vh;
	}
	.boxBotaoInternoMaisMenos {
		height: 3.6vh;
		margin-bottom: 3vh;
	}
	.boxCarrinhoAltura {
		height: 91vh;
		overflow-y: auto;
	}
	.divider {
		width: 94vw;
	}
	.dividerOpcional {
		width: 94vw;
	}
	.nomeOpcional {
		margin-left: 3vw;
	}
	.boxCupom {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 3vh;
		padding-top: 1vh;
	}
	.textFieldCupom .MuiOutlinedInput-notchedOutline {
		border: none;
		border-radius: 1rem;
	}
	.botaoAplicar {
		border: none;
		background: none;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}
	.cupomDescontoTitulo {
		font-size: 1rem;
		display: flex;
		justify-content: flex-start;
		width: 95vw;
	}
	.textFieldCupom {
		background-color: #f5f5f5;
		border-radius: 1rem;
		width: 95vw;
	}
	.preço {
		font-size: 1.1rem;
	}
	.departmentNome {
		font-size: 1.1rem;
	}
	.boxObservacao {
		width: 94vw;
		height: 18.2vh;
		margin-top: 1.5vh;
		margin-left: 3vw;
		margin-right: 3vw;
		border-radius: 0.5rem;
		background-color: #f5f5f5;
	}
	.typoCampoObservacao {
		font-size: 1.1rem;
	}
	.boxCampoObsLength {
		width: 7.5vh;
		height: 1.5vh;
		background-color: gray;
		border-radius: 0.2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
	}
	.boxAlgumaObs {
		height: 5vh;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 0.5rem 0.5rem 0 0;
	}
	.boxFormaPag {
		margin-top: 20vh;
		display: flex;
		flex-direction: column;
	}
	.btnCar {
		width: 4vh;
		font-size: 2.5rem;
		height: 3vh;
		min-width: 0px;
		padding-top: 0.1vh;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.boxRelatedProducts {
		display: flex;
		margin-top: 1vh;
		flex-direction: row;
		overflow-x: auto;
		height: auto;
		height: 16.5vh;
		margin-left: 3vw;
		margin-right: 2vw;
	}

	.boxcardRelatedProducts {
		width: 20%;
		flex-shrink: 0;
		height: 15.2vh;
		border-radius: 1rem;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
		margin-right: 1.8vw;
	}

	.boxContainerRelatedProducts {
		margin: 3vh 0vw 0vh 0vw;
		width: 100vw;
	}

	.typoPecaTambem {
		font-size: 1.25rem;
		margin-left: 3vw;
	}
	.containerTranslation {
		z-index: 999999;
		width: 100vw;
		display: flex;
		justify-content: flex-end;
		justify-content: space-around;
		background: white;
		align-items: center;
	}
	.selectTranslation {
		width: 25vw;
		background-color: white;
	}
	.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
		border-radius: 1.2vw;
	}
	.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		display: flex;
		align-items: center;
	}
	.imgLogoZerofila {
		height: 3em;
	}
	.languageOption {
		display: flex;
		align-items: center;
	}
	.boxLogo {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.produtoIndisponivel {
		padding: 3vw;
		text-align: center;
	}
	.camadaProdutoIndisponivel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
		color: #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1rem;
	}
	.titleInput {
		margin-top: 3vh;
		font-size: 1.9rem;
		text-align: center;
		padding: 0 3vw;
	}
	.containerTake {
		display: flex;
		margin-top: 4vh;
		text-align: center;
		width: 70%;
		justify-content: space-evenly;
	}
}

#autopagamento {
	.boxContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.logoEmpresa {
		height: 8vh;
		border-radius: 100vh;
		margin-bottom: 6vh;
	}

	.typoEscaneie {
		font-size: 2.5rem;
		margin-bottom: 1.5vh;
	}
	.typoAproxime {
		font-size: 2.1875rem;
	}
	.boxArrowback {
		display: flex;
		align-items: center;
		margin-top: 3vh;
	}
	.typoReturn {
		font-size: 1.5625rem;
	}
	.boxImgScanner {
		display: flex;
		justify-content: center;
		height: 50vh;
	}

	.boxImgScannerModal {
		display: flex;
		justify-content: center;
		margin: 2vh 0;
		height: 35vh;
	}
	.boxTextField {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.8vh;
	}
	.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
		border-radius: 0.9375rem;
		border: 1px solid #f5f5f5;
		background-color: #f5f5f5;
		height: 3.3vh;
	}
	.textfield {
		width: 95vw;
		margin-bottom: 1vh;
	}
	.typoDigiteCod {
		font-size: 1.25rem;
		display: flex;
		justify-content: flex-start;
	}

	.boxModalCard {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
		padding: 16px;
		border-radius: 1.125rem;
		padding: 3vh 1vh;
	}
	.boxModalCard:focus {
		outline: none;
	}
	.autoButtons {
		border: 1px solid #c8c7c7;
		width: 95vw;
		height: 3vh;
		border-radius: 0.9375rem;
		text-transform: capitalize;
		font-size: 1.1875rem;
	}
	.typoCard {
		font-size: 1.125rem;
	}
	.boxContainerCard {
		margin: 2.5vh 0;
		max-height: 50vh;
		overflow-y: auto;
	}
	.typoCardNumber {
		font-size: 1.3rem;
	}
	.boxContainerConsumption {
		display: flex;
		justify-content: space-between;
	}
	.typoCpfName {
		font-size: 1.125rem;
		color: #717171;
	}
	.typoValueCard {
		font-size: 1.25rem;
	}
	.typoValue {
		margin-top: 1vh;
		font-size: 1.25rem;
	}

	.boxPersonalData {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	.boxContainerConsumption {
		margin-top: 1.5vh;
	}
	.typoProduct {
		font-size: 1.25rem;
		color: #717171;
	}
	.typoConsumption {
		font-size: 1.25rem;
		margin-top: 1vh;
	}
	.finalPrice {
		font-size: 1.25rem;
		color: #3e3e3e;
	}
	.boxFinalPrice {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.5vh;
	}
	.boxScan {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.boxClose {
		color: #a2a2a2;
		font-size: 2rem;
		display: flex;
		justify-content: flex-end;
	}
	.typoTitleModal {
		font-size: 1.5rem;
		margin: 1vh 0 0.6vh 0;
	}
	.typoTextModal {
		font-size: 1.25rem;
	}
	.boxCardCart {
		max-height: 50vh;
		margin: 0 3vw;
		overflow-y: auto;
	}
}

@media (orientation: landscape) {
	#autoatendimento .logoClientInformation {
		margin-top: 3vh;
	}

	#autoatendimento .confirmBtnMoreInfo {
		top: 80vh;
		height: 8vh;
	}

	#autoatendimento .returnBtnMoreInfo {
		top: 90vh;
	}

	#autoatendimento .totemOptionals {
		display: none;
	}

	#autoatendimento .tabletOptionals {
		z-index: 999;
		display: block;
	}

	#autoatendimento .btnAddTablet {
		display: flex;
	}

	#autoatendimento .autoLogoButton {
		background-color: white;
		width: 65vw;
		padding: 1.2vh 1.2vw;
		border-radius: 1vh;
	}

	#autoatendimento .autoBanner {
		display: none;
	}

	#autoatendimento .tabletPriceTag {
		font-size: 1.125rem;
		display: block;
	}

	#autoatendimento .totemPriceTag {
		display: none;
	}

	#autoatendimento {
		.boxBotaoIniciar {
			height: 30vh;
		}

		.iconTouch {
			width: 7vw;
		}

		.botaoIniciarConteudo {
			font-size: 1.5rem;
		}
	}

	#autoatendimento .productsOverflow {
		gap: 2.5vh;
	}

	#autoatendimento .productsOverflowGrid {
		grid-template-columns: 1fr;
		gap: 2.5vh 2vw;
	}

	#autoatendimento {
		.bannerFundo {
			height: 15vh;
		}
		.logo {
			height: 15vh;
			width: 15vh;
		}
	}

	#autoatendimento {
		.telaBig {
			height: 75.7vh;
			padding-bottom: 2vh;
		}
		.telaSmall {
			height: 59vh;
			padding-bottom: 2vh;
		}

		.containerProdutos {
			margin-top: 12vh;
		}

		.boxcardProduto {
			height: 25vh;
		}

		.boxDepartamento {
			height: 25vh;
		}
		.cardProduto {
			flex-direction: row;
			/* padding: 0.5rem; */
		}
		.boxCardImg {
			height: 100%;
			width: 60%;
		}

		.cardImg {
			border-radius: 1rem 0 0 1rem;
		}
		.boxConteudo {
			align-items: flex-start;
			padding-left: 2vw;
			padding-right: 2vw;
		}
		.boxConteudoPrecoDescricao {
			align-items: flex-start;
			flex-direction: column;
		}
		.nomeProduto {
			text-align: start;
			font-size: 1rem;
		}
		.descricaoProduto {
			display: flex;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			max-height: 3em; /* Ajuste conforme a altura da linha */
			line-height: 1.5em; /* Ajuste conforme a altura da linha */
			height: 3em; /* Altura fixa para garantir o corte */
			font-size: 0.875rem;
		}
	}

	#autoatendimento {
		.boxDetailsProduct {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.bannerInterno {
			width: 45vw;
			border-radius: 0.5rem;
			margin-top: 3vh;
			margin-left: 3vw;
			/* margin-right: 3vw; */
		}

		.boxGrid {
			width: 1vw;
		}
		.boxDetailsProductTitulo {
			width: 45vw;
			padding: 2vh 0 6vh 0;
		}
		.detailsProductTitulo {
			font-size: 1.125rem;
		}
		.detailsDescricao {
			width: 45vw;
		}
		.detailsProductDescricao {
			font-size: 0.875rem;
		}
		.boxOpicional {
			width: 45vw;
			margin-top: 4vh;
		}
		.boxTitulo {
			height: 8vh;
		}
		.opcaoFontSize {
			font-size: 0.875rem;
		}
		.opcionais {
			height: 8vh;
		}
		.escolhaMinMax {
			font-size: 0.75rem;
		}
		.opcionalObrigatorio {
			height: 5vh;
		}
		.divider {
			width: 45vw;
		}
		.dividerOpcional {
			width: 45vw;
		}
	}

	#autoatendimento {
		.botaoAdicionar {
			display: none;
		}
		.botao {
			height: 7vh;
		}
	}

	#autoatendimento {
		.boxCarrinho {
			height: 18vh;
		}
		.botaoCarrinho {
			height: 9vh;
			width: 40vw;
			box-shadow: none;
			font-size: 1rem !important;
		}
		.totalItem {
			font-size: 0.875rem;
		}
		.total {
			font-size: 0.85rem;
		}
		.item {
			font-size: 0.75rem;
		}
		.valor {
			font-size: 1.375rem;
		}
	}

	#autoatendimento {
		.imgProduto {
			height: 20vh;
		}
		.btnCarrinhoMaisMenos {
			height: 8vh;
		}
	}

	#autoatendimento {
		.boxLevar {
			height: 50vh;
		}
		.circuloImg {
			height: 12vw;
			width: 12vw;
		}
		.imgConsumir {
			width: 10vh;
		}
	}

	#autoatendimento {
		.formaPag {
			height: 12vh;
		}
	}

	#autoatendimento {
		.botaoInternoMaisMenos {
			width: 90vw;
			height: 7vh;
		}
		.boxBotaoInternoMaisMenos {
			height: 7vh;
			margin-bottom: 1.6vh;
		}
	}

	#autoatendimento {
		.boxCupom {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 15vh;
			padding-top: 5vh;
		}
	}

	#autoatendimento .boxObservacao {
		width: 45vw;
		height: 20.5vh;
		margin-top: 4.2vh;
	}

	#autoatendimento .btnCar {
		background: #3e3e3e;
		width: 3vw !important;
		height: 5vh !important;
	}
	#autoatendimento .boxCampoObsLength {
		height: 3vh;
		width: 7vw;
	}
}

@media (orientation: portrait) and (max-height: 1000px) {
	#autoatendimento {
		.returnBtnMoreInfo {
			font-size: 1.5rem;
		}
		.boxRelatedProducts {
			height: 22vh;
		}
		.confirmBtnMoreInfo {
			font-size: 1.6rem;
		}

		.boxBtnComandaPedido {
			padding-top: 40vh;
		}

		.boxBotaoIniciar {
			left: 20vw;
			height: 25vh;
			width: 45vw;
		}

		.botaoIniciarConteudo {
			font-size: 1.2rem;
		}
		.botaoInternoMaisMenos {
			padding: 2.5vh 0vw;
			height: 0vh;
		}
	}

	#autoatendimento {
		.departmentNome {
			font-size: 0.875rem;
		}
	}

	#autoatendimento {
		.productsOverflow {
			gap: 1vh;
		}

		.productsOverflowGrid {
			grid-template-columns: 1fr;
			gap: 1.5vh 2vw;
		}
		.cardProduto {
			flex-direction: row;
		}

		.cardImg {
			border-radius: 1rem 0 0 1rem;
		}

		.boxCardImg {
			height: 100%;
			width: 70%;
		}

		.boxConteudo {
			align-items: flex-start;
			padding-left: 2vw;
			padding-right: 2vw;
		}

		.nomeProduto {
			text-align: start;
			font-size: 0.875rem;
		}

		.boxConteudoPrecoDescricao {
			align-items: flex-start;
			flex-direction: column;
		}

		.descricaoProduto {
			display: flex;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			max-height: 3em;
			line-height: 1.5em;
			/* height: 3em; */
			font-size: 0.75rem;
		}

		.preços {
			font-size: 0.875rem;
		}
	}

	#autoatendimento {
		.boxcardRelatedProducts {
			width: 25vw;
			height: 18vh;
		}
		.detailsProductTitulo {
			font-size: 1.4rem;
		}
		.detailsProductDescricao {
			font-size: 0.875rem;
		}
		.totemPriceTag {
			font-size: 1.4rem;
		}
		.boxDetailsProductTitulo {
			margin-top: 1.8vh;
			margin-bottom: 2vh;
		}
		.detailsDescricao {
			margin-top: 0vh;
		}
		.botao {
			padding: 2.5vh;
		}
		.boxObservacao {
			height: 24.5vh;
		}
		.typoCampoObservacao {
			font-size: 0.875rem;
		}
		.boxCampoObsLength {
			width: 8.5vh;
			height: 2vh;
		}

		.opcionalObrigatorio {
			height: 2.5vh;
		}
		.typoObrigatorio {
			font-size: 0.75rem;
		}
		.boxOpicional {
			margin-top: 3.5vh;
		}
		.escolhaOpcao {
			font-size: 1.1rem;
		}

		.escolhaMinMax {
			font-size: 0.875rem;
		}
		.opcaoFontSize {
			font-size: 1rem;
		}
	}

	#autoatendimento {
		.bannerFundo {
			height: 10vh;
		}
	}

	#autoatendimento {
		.botaoCarrinho {
			font-size: 0.75rem;
			font-size: 1.1rem !important;
		}
		.boxCarrinho {
			padding-left: 4vw;
			padding-right: 4vw;
			padding: 2vh;
		}
		.botaoCarrinho {
			height: 5vh;
		}
		.total {
			font-size: 1.25rem;
		}
		.valor {
			font-size: 1.25rem;
		}
		.item {
			font-size: 0.875rem;
		}
	}

	#autoatendimento {
		.btnCarrinhoMaisMenos {
			height: 5vh;
		}
		.totemTituloCarrinho {
			font-size: 0.875rem;
		}
		.totemPrecoCarrinho {
			font-size: 1rem;
		}
		.imgProduto {
			height: 10vh;
		}
	}

	#autoatendimento {
		.boxLevar {
			height: 30vh;
			width: 40vw;
			margin-right: 2vw;
			margin-left: 2vw;
		}
		.circuloImg {
			height: 20vw;
			width: 20vw;
		}
		.imgConsumir {
			width: 10vh;
		}
		.logoClientInformation {
			margin-top: 9vh;
		}
		.boxFormaPag {
			padding: 0 5vw;
			margin-top: 15vh;
		}
		.formaPag {
			height: 8.5vh;
		}
		.imgLogoZerofila {
			height: 1.5em;
		}
		.nameFlag {
			font-size: 0.75rem;
		}
		.slogoZerofila {
			font-size: 0.625rem;
			text-align: center;
		}
	}

	#autopagamento {
		.typoEscaneie {
			font-size: 2rem;
		}
		.typoAproxime {
			font-size: 1.8rem;
			text-align: center;
		}
		.boxImgScanner {
			height: 38vh;
		}
		.autoButtons {
			height: 4vh;
		}
		.boxImgScannerModal {
			height: 30vh;
		}
	}
}
