.productItem {
	@apply flex items-center rounded-2xl text-left w-full;
	letter-spacing: normal !important;
	text-transform: none !important;
	box-shadow: 0px 2px 6px var(--shadow-color);
	margin-bottom: 16px !important;
	padding: 12px !important;
}

.productItem .productImg {
	@apply bg-cover bg-center w-36 h-36 rounded mr-4;
}

.productItem .productName {
	@apply mb-1 text-base font-medium;
	color: var(--first-color);
}

.productItem .productDesc {
	@apply text-xs;
	color: var(--second-color);
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.productItem .productPrice {
	@apply mt-3 font-medium text-base;
	color: var(--first-color);
}

.productItem .productPrice--withPromo {
	text-decoration: line-through;
	opacity: 0.6;
}

.productItem .productPrice--promo {
	margin-top: 0;
}
