#search .MuiFormLabel-root,
#search .MuiOutlinedInput-root,
#search .searchInput svg,
#search .optionalRadio svg,
#search .optionalRadio .MuiFormControlLabel-label {
  color: var(--second-color) !important;
}

#search .MuiOutlinedInput-notchedOutline {
  @apply rounded-r-none border-r-0;
  border-color: var(--second-color) !important;
}

#search .formInput {
  @apply mb-6 flex;
}

#search .inputBtn {
  @apply w-6 h-14 rounded-l-none;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}

#search .footerBlock {
  @apply py-6 px-4;
}

#search .footerBtn {
  @apply w-full h-14 mt-8 text-lg font-semibold;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}

#search .groupContent {
  @apply w-full py-6;
  border-top: 1px solid #dddddd;
}

#search .groupTitle {
  @apply font-semibold text-lg mb-2;
}

#search .groupRadio {
  @apply grid;
  grid-template-columns: 1fr 1fr;
}

#search .optionalRadio .MuiFormControlLabel-label {
  @apply font-semibold text-base;
}
