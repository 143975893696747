#registerAddress .container {
  @apply px-4 pt-6;
}

#registerAddress .field {
  @apply mb-3;
}

#registerAddress .blockSearch {
  @apply mb-6 flex relative;
}

#registerAddress .blockSearch .btnSearch {
  @apply w-6 h-14 rounded-l-none absolute right-0;
  color: var(--second-color);
}

#registerAddress .findCep {
  @apply flex items-center justify-center mt-5 gap-1 mb-2;
  color: var(--second-color);
}

#registerAddress .findCepTxt {
  @apply uppercase text-xs;
  color: var(--second-color);
}

#registerAddress .findCepLink {
  @apply text-xs font-bold;
  color: var(--second-color);
}

#registerAddress .getLocation {
  @apply w-full h-14 text-sm font-bold text-white shadow-none gap-3 mt-6;
  background-color: #5e5e5e;
}

#registerAddress .btnBlock {
  @apply p-4;
}

#registerAddress .btnSave {
  @apply h-14 mt-4 mx-auto w-full block shadow-none text-lg text-white font-semibold;
}
