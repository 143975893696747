#order .content {
  @apply p-4;
}

#order .infos {
  @apply pb-8 border-b;
  border-color: var(--second-color);
}

#order .info {
  @apply text-xl;
  color: var(--first-color);
}

#order .list {
  @apply pt-8;
}

#order .item {
  @apply mb-4 flex items-center gap-2;
}

#order .itemImg {
  @apply w-14 h-14 bg-cover bg-center rounded-md;
}

#order .itemContent {
  @apply flex-1;
}

#order .itemPrice {
  @apply text-xl font-semibold;
  color: var(--first-color);
}

#order .itemPrice span {
  @apply text-sm;
}

#order .itemTitle,
#order .itemQtd {
  color: var(--second-color);
}

#order .itemOptions {
  @apply text-xs;
  color: var(--second-color);
}
