#queue .content {
	@apply flex-1;
}

#queue .filaEsperaSection {
	min-height: 87vh;
}

#queue .header {
	@apply px-4 pt-6;
}

#queue .clientName {
	@apply text-xl font-semibold;
	color: var(--first-color);
}

#queue .formItem {
	@apply flex items-center;
}

#queue .formIcon {
	@apply w-12 border-t flex items-center justify-center relative h-14;
	border-color: var(--second-color);
}

#queue .formInput {
	@apply w-full  bg-transparent;
}

#queue .formInput fieldset {
	@apply border-x-0 rounded-none border-b-0;
}

#queue .error {
	@apply mb-1;
}

#queue .tabsItem {
	@apply mb-2;
}

#queue .tabsHead {
	@apply h-9 flex items-center justify-between px-3 w-full;
	background-color: #ddd !important;
	box-shadow: none !important;
}

#queue .tabsTitle {
	@apply text-sm;
	color: #656572;
	text-transform: none;
}

#queue .tabsBody {
	@apply mt-2;
}

#queue .tabsTxt {
	@apply text-sm;
	color: var(--second-color);
	white-space: break-spaces;
}

#queue .text {
	color: var(--first-color);
}

#queue .center-div {
	width: 100%;
	text-align: center;
}

#queue .centered-text {
	text-align: center;
	margin-bottom: 0.5rem;
}

#queue .top-distance {
	margin-top: 1rem;
	font-size: 0.9rem;
}

#queue .smaller-text {
	font-size: 0.9rem;
}

#queue .bt-top {
	margin-bottom: 1rem;
}

#queue .tf-top {
	margin-top: 2.4rem;
}

#queue .circular {
	color: var(--main-color) !important;
}

#queue .btn-quit {
	@apply !font-semibold;
	margin-top: -0.2rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-radius: 0.5rem;
	text-transform: none;
	color: var(--main-color) !important;
	border-color: var(--main-color) !important;
}

#queue .real-time-box {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
}

#queue .organizer {
	position: relative;
	display: inline-flex;
}

#queue .waiting-queue-section {
	min-height: 80vh;
}

#queue .iconHolder svg {
	@apply mx-auto mb-9 w-16 h-16 mt-6;
}

#queue .typoBox {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnStyle {
	@apply h-14 w-full font-bold;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	font-size: 1.125rem !important;
	text-transform: initial !important;
	color: #fff !important;
}
