#header .title {
  @apply text-2xl font-semibold;
}

#header .navMenu {
  @apply flex justify-center items-center;
}

#header .navMenuBetween {
  @apply justify-between;
}

#header .callWaiter {
  @apply w-10 h-10 min-w-0 shadow-none p-0 rounded-lg flex items-center justify-center;
  background-color: #96969a !important;
  color: #96969a;
  box-shadow: none !important;
}
