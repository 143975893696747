#companiesList .container {
  @apply p-4;
}

#companiesList .searchBlock {
  @apply mb-6 flex relative;
}

#companiesList .btnSearch {
  @apply w-6 h-14 rounded-l-none absolute right-0;
  color: var(--second-color);
}
