#successPage .container {
  @apply p-6;
}

#successPage .header {
  @apply mb-8;
  text-align: center;
}

#successPage .icon svg {
  @apply mb-6 mx-auto;
}

#successPage .title {
  @apply mb-2 font-semibold text-xl;
  color: var(--first-color);
}

#successPage .subtitle {
  @apply text-sm;
  color: var(--first-color);
}

#successPage .card {
  @apply p-3 rounded-xl mb-4;
  background: rgba(65, 168, 57, 0.1);
}

#successPage .card .row {
  @apply flex justify-between items-center mb-5 gap-4;
}

#successPage .card .row:last-child {
  @apply mb-0;
}

#successPage .card .text {
  @apply flex-1 text-sm;
  color: var(--first-color);
}

#successPage .card .tag {
  @apply px-2 h-5 flex items-center justify-center font-semibold text-xs capitalize rounded-xl;
  color: #fff;
  background-color: var(--main-color);
}

#successPage .card .priceTitle {
  @apply flex-1 text-sm font-semibold;
  color: var(--first-color);
}

#successPage .card .price {
  @apply text-xl font-semibold;
  color: var(--first-color);
}

#successPage .card .price span {
  @apply text-sm font-medium;
}

#successPage .btnQrCode {
  @apply p-0;
}

#successPage .boxQrCode {
  @apply p-2 rounded-md ml-2;
  background-color: #fff;
}

#successPage .boxQrCode svg {
  @apply w-10 h-10;
}

#successPage .boxLink {
  @apply px-4 py-5 rounded-xl;
  min-height: 96px;
  background-color: rgba(191, 190, 190, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.09);
}

#successPage .linkMsg {
  @apply text-lg text-center font-semibold;
  color: var(--first-color);
  margin-bottom: -8px;
}

#successPage .btnLink {
  @apply w-44 h-10 mt-4 text-base mx-auto block;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}

#successPage .btnLink.Mui-disabled {
  @apply opacity-50;
}

#successPage .footerBlock {
  @apply py-6 px-4;
}

#successPage .btnFooter {
  @apply w-full h-14 text-lg font-semibold;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}
