.appBarLayout {
	display: flex !important;
	flex-direction: column !important;
}

.boxAppBarLayout {
	display: flex !important;
	flex-direction: column !important;
	align-items: center;
}

.appBar {
	display: grid !important;
	grid-template-columns: 3fr 1fr !important;
	color: var(--bg-navigatior) !important;
	background-color: var(--main-color) !important;
	align-items: center !important;
}

.typoNomeCliente {
	font-size: larger;
	text-align: center;
}

.infoRestaurante {
	font-size: 0.75rem;
	color: var(--third-color);
	text-align: center;
}

.newCategoriesTab {
	background-color: var(--main-color) !important;
	width: 100vw !important;
	color: white !important;
	overflow-x: scroll !important;
	display: flex !important;
	flex-shrink: 0 !important;
}

.iconButtonCategories {
	@apply !font-bold !text-4xl;
	background-color: white !important;
	margin: 0.2rem !important;
	border-radius: 50% !important;
	width: 3.2rem !important;
	height: 3.2rem !important;
	color: var(--main-color) !important;
}

.fixedDepartments {
	@apply !fixed !left-0 !w-full !pt-3 !pb-3 !hidden !opacity-0;
	background-color: var(--bg-navigatior) !important;
	transition: all 0.6s !important;
	z-index: 2 !important;
}

.fixedDepartments.show {
	@apply !opacity-100 !block;
}

.tabsList {
	@apply min-h-0 !important;
	max-width: 767px !important;
	margin: 0 auto !important;
}

.customTab {
	@apply !font-semibold !text-sm !min-h-0 !py-2 !rounded-lg;
	color: var(--third-color) !important;
}

.customTab:first-child {
	@apply ml-4 !important;
}

.customTab:last-child {
	@apply mr-4 !important;
}

.customTab.active {
	background-color: var(--main-color) !important;
	color: #fff !important;
}

.iconButtonBox {
	max-width: 4rem !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}

.iconText {
	@apply !font-normal !text-xs;
	max-width: 4rem !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	float: left !important;
}

.enterpriseMobileName {
	@apply !pl-2 !text-xl !font-bold !my-2;
	color: white !important;
}

.deliveryTime {
	font-size: 0.75rem;
}

.boxDeliveryTime {
	background: #f5f5f5;
	padding: 2vh;
	margin: 0vh 3vh 3vh;
	border-radius: 0.5rem;
	width: 95%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.gridLeftSpace {
	@apply mx-5 !font-semibold !text-xl mt-2;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: space-between !important;
}

.gridRightSpace {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	gap: 0.5vw !important;
	height: 4rem !important;
	justify-content: flex-start !important;
}

.notificationIcon {
	position: absolute !important;
	margin-left: auto !important;
	margin-right: auto !important;
	left: 0 !important;
	right: 0 !important;
	text-align: center !important;
	font-size: 20px !important;
	color: var(--first-color) !important;
}

.notificationIconBox {
	position: absolute !important;
	width: 1.2rem !important;
	top: 0.9rem !important;
	right: 0.1rem !important;
	font-size: 20px !important;
	color: var(--first-color) !important;
}

.notificationText {
	@apply !font-extrabold;
	position: absolute !important;
	margin-left: auto !important;
	margin-right: auto !important;
	left: 0 !important;
	right: 0 !important;
	top: 0.05rem !important;
	text-align: center !important;
	font-size: 12px !important;
	color: var(--main-color) !important;
}

.appBarIcons {
	margin-top: 1rem;
	color: #ffffff;
	background: #dcdcdc77; 
	display: flex;
	justify-content: center;
	align-items: center;

}

.boxAvalie {
	border: 1px solid #3e3e3e;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	height: 5vh;
	justify-content: center;
	color: rgb(0, 0, 0);
	position: sticky;
	background: white;
}

.containerBoxRating {
	max-height: 34vh;
	overflow-x: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.containerBoxRating::-webkit-scrollbar {
	width: 8px;
}

.containerBoxRating::-webkit-scrollbar-thumb {
	background: #adadad;
	border-radius: 4px;
}

.containerBoxRating::-webkit-scrollbar-track {
	background: #f5f5f5;
}

.boxRating {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f5f5f5;
	border-radius: 0.75rem;
	width: 90%;
	padding: 1vh;
	margin-bottom: 1vh;
}

.boxObBtn {
	display: flex;
	flex-direction: column;
	width: 98%;
	align-items: center;
}

.btnAvaliacao {
	width: 90%;
	padding: 1vh;
	height: 3.5rem;
	margin-top: 1.5vh;
}

.modalAvaliação {
	position: relative;
	/* z-index: 50; */
	display: flex;
	width: 90%;
	align-items: center;
	margin: auto;
	justify-content: center;
	border-radius: 1.5rem;
	border-width: 1px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	border-color: var(--second-color);
	background-color: var(--bg-color);
	max-width: 500px;
	flex-direction: column;
	top: 40vh;
	height: 20vh;
}

.btnAvaliação {
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: 3.5rem;
	width: 100%;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
	background-color: var(--main-color) !important;
	box-shadow: none !important;
	text-transform: none;
	color: #fff !important;
	max-width: 260px;
}

.textModalavaliação {
	margin-bottom: 0.5rem;
	text-align: center;
	font-weight: 600;
	color: var(--first-color);
}

.typoAvaliacao {
	font-size: 1.125rem;
	width: 80%;
	text-align: center;
	margin: 4.5vh 0 3vh 0;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
	border-radius: 0.5rem;
}

.backgroundImgBar {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-size: cover;
	background-position: center;
}

.logoAvaliacao {
	width: 8vh;
	height: 8vh;
	border-radius: 100%;
	position: absolute;
	z-index: 99999;
	top: 10vh;
}

.boxBackground {
	position: absolute;
	top: 9vh;
	height: 2vh;
	width: 100%;
	display: flex;
	background: white;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
}

.boxBackgroundRating {
	position: absolute;
	top: 14vh;
	height: 2vh;
	width: 100%;
	display: flex;
	background: white;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
}

.boxBackgroundAvaliacao {
	position: absolute;
	top: 14vh;
	height: 2vh;
	width: 100%;
	display: flex;
	background: white;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
}

.boxBackgroundLogo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoAvaliacaoBar {
	width: 8vh;
	height: 8vh;
	border-radius: 50%;
	border: 0.1rem solid rgb(255, 255, 255);
	box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
}

.boxAppBar {
	display: flex;
	position: absolute;
	width: 100%;
	padding: 0 3vw;
	align-items: center;
	justify-content: space-between;
}

.iconCloseRating {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -4px;
	right: -8px;
	font-size: 1.2rem;
	color: rgba(194, 194, 194, 0.9);
}

.boxConteinerRatingIcon {
	position: fixed;
	/* left: 5vw; */
	z-index: 99;
	top: 11vh;
	margin-left: 4vw;
}

.boxRatingIcon {
	/* background: #fff; */
	height: 4rem;
	border-radius: 100%;
	width: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
}

.btnRating {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.typoAvalieIcon {
	/* color: #3e3e3e; */
	color: rgba(194, 194, 194, 0.9);

	font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
}
