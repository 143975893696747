#ticket .fontPoppins {
	font-family: var(--font-poppins400) !important;
}

#ticket .ticketScrollBox {
	width: 100vw;
	overflow: scroll;
	display: flex;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#ticket .seeMoreBox {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

#ticket .ticketCard {
	border-radius: 0.5rem;
	min-width: max-content;
	max-height: 86px;
	height: 86px;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 2rem;
	border: 0px;
	box-shadow: 15px 15px 30px 0px #d3d1d840;
	box-shadow: 0px 4px 4px 0px #0000001a;
}

#ticket .ticketCardContent {
	display: flex;
	gap: '2rem';
	align-items: center;
	justify-content: center;
	height: 86px;
	width: max-content;
}

#ticket .ticketCardBox {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 200px;
}

#ticket .usedTicketOverflowBox {
	width: 100vw;
	overflow-y: scroll;

	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	scrollbar-color: transparent !important;
	scrollbar-darkshadow-color: transparent !important;
	scrollbar-base-color: transparent !important;
}

#ticket .ticketmenu {
	box-shadow: 0px -8px 80px 0px #56585c0f;
	border-top: 1px solid #eaeaea;
	position: fixed;
	bottom: 0;
	height: 10vh;
	background-color: black;
	background: #ffffff;
	width: 100vw;
}
