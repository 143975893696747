#modalWaiter {
  @apply flex items-center justify-center h-full w-full px-6;
}

#modalWaiter .content {
  @apply rounded-3xl py-6 px-8 w-full flex items-center justify-center text-xl font-semibold text-center flex-col;
  background-color: var(--bg-color);
}

#modalWaiter .icon {
  @apply w-10 h-10 min-w-0 shadow-none p-0 rounded-lg flex items-center justify-center;
  color: var(--bg-icon);
  background-color: var(--bg-icon);
}

#modalWaiter .loading {
  @apply mb-4 w-full;
  color: var(--main-color);
}

#modalWaiter .title {
  @apply font-semibold mb-8 text-center mt-5;
  color: var(--first-color);
}

#modalWaiter .title-noMargin {
  @apply mb-0 mt-0;
}

#modalWaiter .rowBtn {
  @apply flex gap-4 w-full;
}

#modalWaiter .btn {
  @apply w-full h-14 text-lg font-semibold mx-auto block;
  max-width: 220px;
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  text-transform: none;
  color: #fff !important;
}
