#modalThumb {
  @apply flex items-center justify-center fixed top-0 left-0 w-full;
  height: 100%;
  z-index: 999;
}

#modalThumb .MuiBackdrop-root {
  top: -50px;
  height: 100vh;
}

#modalThumb .modalContent {
  @apply w-full h-full flex flex-col justify-between relative;
}

#modalThumb .modalBody {
  @apply w-full py-6 relative z-10;
}

#modalThumb .modalFooter {
  @apply w-full py-6 px-4 relative z-10 text-left;
}

#modalThumb .modalFooter * {
  color: #fff;
}

#modalThumb .modalFooterTitle {
  @apply font-semibold;
}

#modalThumb .modalOverlay {
  @apply absolute w-full h-full top-0 left-0;
  background-color: rgba(0, 0, 0, 0.93);
}
